import React, { useCallback, useEffect, useState } from 'react';
import { Form, Select } from 'antd';

import CModal from '../commons/c-modal/CModal';
import { useTeamsStore } from '../hooks/useTeamsStore';
import { useDataSource } from '../helpers/DataSourceContext';

interface AssignTeamsModalProps {
  isModalVisible: boolean;
  onCloseModal: VoidFunction;
  refreshList: (params: { editedRowId: string }) => void;
  alert: any;
}

type AssignTeamsFormType = {
  teams: string[];
};

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AssignTeamsModal = ({ isModalVisible, onCloseModal, alert, refreshList }: AssignTeamsModalProps) => {
  const [teamsOptions, setTeamsOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm<AssignTeamsFormType>();

  const { teams, isTeamsLoading } = useTeamsStore();
  const { dataSource } = useDataSource();

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({
        teams: alert.teams?.map((teams: any) => teams.team_uuid),
      });
    }
  }, [isModalVisible]);

  const fetchTeamsOptions = useCallback(async () => {
    try {
      const _teamsOptions = teams.map((team) => ({
        label: team.name,
        value: team.uuid,
      }));

      setTeamsOptions(_teamsOptions);
    } finally {
    }
  }, [teams, setTeamsOptions]);

  useEffect(() => {
    void fetchTeamsOptions();
  }, [teams]);

  const handleSubmit = async (item: any) => {
    try {
      setConfirmLoading(true);
      await dataSource?.services?.alertService?.assignAlertTeams(alert.uuid, item.teams);
      refreshList({ editedRowId: alert.uuid });
      handleClose();
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleClose = useCallback(() => {
    form.resetFields();
    onCloseModal();
  }, []);

  return (
    <CModal
      title={`Assign teams for alert: ${alert?.uuid}`}
      open={isModalVisible}
      onOk={form.submit}
      onCancel={handleClose}
      confirmLoading={confirmLoading}
      maskClosable={false}
      style={{ textAlign: 'start' }}
    >
      <Form {...formLayout} form={form} name="control-hooks" onFinish={handleSubmit} style={{ maxWidth: '42rem' }}>
        <Form.Item name="teams" label="Teams">
          <Select options={teamsOptions} placeholder="Select Teams" loading={isTeamsLoading} mode="multiple" />
        </Form.Item>
      </Form>
    </CModal>
  );
};

export default AssignTeamsModal;
