import React, { useCallback, useState } from 'react';
import { DatePicker, Form, Select } from 'antd';

import CModal from '../commons/c-modal/CModal';
import { generateSelectOptions } from '../utils';
import { AlertsStatuses } from '../types';
import { useDataSource } from '../helpers/DataSourceContext';

interface BulkUpdateStatusModalProps {
  isModalVisible: boolean;
  onCloseModal: VoidFunction;
  refreshList: (params: { editedRowId?: string }) => void;
  alertUUIDs: string[];
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const alertsStatusOptions = generateSelectOptions(AlertsStatuses);

const BulkUpdateStatusModal = ({
  isModalVisible,
  onCloseModal,
  alertUUIDs,
  refreshList,
}: BulkUpdateStatusModalProps) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [alertStatus, setAlertStatus] = useState<string | undefined>(undefined);
  const [form] = Form.useForm<any>();

  const { dataSource } = useDataSource();

  const handleClose = useCallback(() => {
    form.resetFields();
    onCloseModal();
    setAlertStatus(undefined);
  }, []);

  const updateAStatus = useCallback(async (uuid: string, status: string) => {
    try {
      return await dataSource?.services?.alertService?.updateAlertStatus(uuid, status);
    } catch {}
  }, []);

  const updateAStatusAndAckTimeout = useCallback(async (uuid: string, status: string, acknowledgeTimeout: string) => {
    try {
      return await dataSource?.services?.alertService?.updateAlertStatusAndAckTimeout(uuid, status, acknowledgeTimeout);
    } catch {}
  }, []);

  const handleSubmit = useCallback(
    async (item: any) => {
      try {
        const status = item.status;
        const acknowledge_timeout = item?.acknowledge_timeout ? item.acknowledge_timeout?.toISOString() : '';
        setConfirmLoading(true);
        if (status === 'acknowledged') {
          await Promise.all(
            alertUUIDs.map(async (uuid: string) => {
              return await updateAStatusAndAckTimeout(uuid, status, acknowledge_timeout);
            })
          );
        } else {
          await Promise.all(
            alertUUIDs.map(async (uuid: string) => {
              return await updateAStatus(uuid, status);
            })
          );
        }
        refreshList({});
        handleClose();
      } finally {
        setConfirmLoading(false);
      }
    },
    [alertUUIDs]
  );

  return (
    <CModal
      title="Bulk update alert status"
      open={isModalVisible}
      confirmLoading={confirmLoading}
      onOk={form.submit}
      onCancel={handleClose}
      style={{ textAlign: 'start' }}
      maskClosable={false}
    >
      <Form
        {...formLayout}
        form={form}
        name="control-hooks"
        onFinish={handleSubmit}
        style={{ maxWidth: '42rem', marginTop: '2.5rem' }}
        layout="vertical"
      >
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select
            options={alertsStatusOptions}
            onChange={(value) => {
              setAlertStatus(value);
            }}
          />
        </Form.Item>

        {alertStatus === 'acknowledged' && (
          <Form.Item name="acknowledge_timeout" label="Acknowledge timeout">
            <DatePicker showTime format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} />
          </Form.Item>
        )}
      </Form>
    </CModal>
  );
};

export default BulkUpdateStatusModal;
