import React from 'react';
import { Popover, Space, Tag } from 'antd';

interface TagType {
  tag: string;
}

interface MetaTagType {
  key?: string;
  value?: string;
}

export const TagsRenderer = (tags: TagType[], record: any) => {
  if (!tags) {
    return '';
  }

  return (
    <Popover
      content={
        <Space size={5} wrap style={{ width: 400 }}>
          {tags
            .sort((a, b) => a.tag.localeCompare(b.tag))
            .map(({ tag }, index) => (
              <Tag color="blue" key={index}>
                {tag}
              </Tag>
            ))}
        </Space>
      }
      title={`Tags of: ${'name' in record ? record?.name : 'title' in record ? record?.title : 'undefined'}`}
    >
      <div>
        {tags.length !== 0 && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
            <Tag
              style={{
                maxWidth: '150px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              color="blue"
            >
              {tags[0].tag}
            </Tag>

            {tags.length > 1 && <strong>{'...'}</strong>}
          </div>
        )}
      </div>
    </Popover>
  );
};

export const MetaTagsRenderer = (tags: MetaTagType[], record: any) => {
  if (!tags) {
    return '';
  }

  return (
    <>
      <Popover
        content={
          <Space size={5} wrap style={{ width: 400 }}>
            {tags.map(({ key, value }, index) => (
              <Tag color="cyan" key={index} style={{ marginRight: '0.25rem' }}>
                {key}: {value}
              </Tag>
            ))}
          </Space>
        }
        title={`Meta Tags of: ${'name' in record ? record?.name : 'title' in record ? record?.title : 'undefined'}`}
      >
        <div>
          {tags.length !== 0 && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
              <Tag
                color="cyan"
                style={{
                  marginRight: '0.25rem',
                  maxWidth: '150px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {tags[0].key}: {tags[0].value}
              </Tag>

              {tags.length > 1 && <strong>{'...'}</strong>}
            </div>
          )}
        </div>
      </Popover>
    </>
  );
};
