import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Space, Tabs, TabsProps, Tag as AntdTag } from 'antd';

import CModal from '../commons/c-modal/CModal';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

enum BulkTagMode {
  add = 'Add',
  delete = 'Delete',
  edit = 'Edit',
}

type ValidDataInputType = any;

interface BulkTagsModalProps {
  isModalVisible: boolean;
  currentItems: ValidDataInputType[];
  onBulkSaveTags: (uuid: string, data: ValidDataInputType) => void;
  onSaveMetaTags: (uuid: string, data: any) => void;
  onCloseModal: VoidFunction;
  refreshList: any;
}

interface OptionsType {
  value: string;
  label: string;
}

interface TagEditType {
  tag: string;
  originalValue: string;
}

interface MetaTagEditType {
  key: string;
  value: string;
  originalPair: string;
}

const modalWidth = 700;

const tag = 'tags';
const metaTag = 'meta_tags';

const addMessage = 'Tags or Meta-tags entered below will be added to all selected networks/devices/points.';
const deleteMessage = 'Remove tag or meta-tags from selected networks/devices/points.';
const editMessage =
  'Select networks/devices/points that you wish to edit by their name and proceed to edit individually.';

const TagEditForm = (props: any) => {
  const { tagsEditForm, handleBulkEditTags } = props;
  return (
    <Form
      name="tags_edit_form"
      form={tagsEditForm}
      onFinish={handleBulkEditTags}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        rowGap: 10,
        width: '100%',
      }}
    >
      <Form.List name="tags_edit">
        {(fields) => (
          <>
            {fields.map((field, idx) => (
              <div key={idx}>
                <Form.Item
                  {...field}
                  label="Tag"
                  name={[field.name, 'tag']}
                  rules={[{ required: true, message: 'Missing value' }]}
                  key={`tag-${idx}`}
                  style={{ marginBottom: 0 }}
                >
                  <Input />
                </Form.Item>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </Form>
  );
};

const MetaTagEditForm = (props: any) => {
  const { metaTagsEditForm, handleBulkEditMetaTags } = props;
  return (
    <Form
      name="meta_tags_edit_form"
      form={metaTagsEditForm}
      onFinish={handleBulkEditMetaTags}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        rowGap: 10,
        width: '100%',
      }}
    >
      <Form.List name="meta_tags_edit">
        {(fields) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div
                key={key}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Form.Item
                  {...restField}
                  name={[name, 'key']}
                  rules={[{ required: true, message: 'Missing Key' }]}
                  style={{ marginBottom: 0, marginRight: 10 }}
                >
                  <Input placeholder="key..." />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  rules={[{ required: true, message: 'Missing Value' }]}
                  style={{ marginBottom: 0 }}
                >
                  <Input placeholder="value..." />
                </Form.Item>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </Form>
  );
};

const BulkTagsModal = (props: BulkTagsModalProps) => {
  const { isModalVisible, currentItems, onBulkSaveTags, onSaveMetaTags, onCloseModal, refreshList } = props;
  const [confirmLoadingTags, setConfirmLoadingTags] = useState(false);
  const [confirmLoadingMetaTags, setConfirmLoadingMetaTags] = useState(false);
  const [message, setMessage] = useState<string | undefined>('');
  const [mode, setMode] = useState<BulkTagMode>(BulkTagMode.add);
  const [activeTabKey, setActiveTabKey] = useState<string>(tag);
  const [tagNamesToRemove, setTagNamesToRemove] = useState<string[]>([]);
  const [tagsRemoveOptions, setTagsRemoveOptions] = useState<OptionsType[]>([]);
  const [metaTagNamesToRemove, setMetaTagNamesToRemove] = useState<string[]>([]);
  const [metaTagsRemoveOptions, setMetaTagsRemoveOptions] = useState<OptionsType[]>([]);
  const [itemToEdit, setItemToEdit] = useState<ValidDataInputType>({} as ValidDataInputType);
  const [tagsForm] = Form.useForm();
  const [metaTagsForm] = Form.useForm();
  const [tagsEditForm] = Form.useForm();
  const [metaTagsEditForm] = Form.useForm();

  useEffect(() => {
    cleanUpStates();
  }, [isModalVisible]);

  useEffect(() => {
    if (itemToEdit) {
      initvalues(itemToEdit);
    }
  }, [itemToEdit]);

  useEffect(() => {
    if (mode === BulkTagMode.delete || mode === BulkTagMode.edit) {
      const tempTags: OptionsType[] = [];
      const tempMetaTags: OptionsType[] = [];
      currentItems.forEach((anItem: ValidDataInputType) => {
        const localTags = anItem.tags;
        const localMetaTags = anItem.meta_tags;

        localTags &&
          localTags.length !== 0 &&
          localTags.forEach((aTag: any) => {
            const searchTagRes = tempTags.find((anExistingTag: OptionsType) => anExistingTag.value === aTag.tag);
            if (searchTagRes === undefined) {
              tempTags.push({ value: aTag.tag, label: aTag.tag });
            }
          });

        localMetaTags &&
          localMetaTags.length !== 0 &&
          localMetaTags.forEach((aMetaTag: any) => {
            const searchMetaTagRes = tempMetaTags.find(
              (anExistingMetaTag: OptionsType) => anExistingMetaTag.value === `${aMetaTag.key}/_/${aMetaTag.value}`
            );
            !searchMetaTagRes &&
              tempMetaTags.push({
                value: `${aMetaTag.key}/_/${aMetaTag.value}`,
                label: `key: ${aMetaTag.key}, value: ${aMetaTag.value}`,
              });
          });
      });
      setTagsRemoveOptions(tempTags);
      setMetaTagsRemoveOptions(tempMetaTags);
    } else if (mode === BulkTagMode.add) {
      tagsForm.setFieldsValue({ tags: [] });
      metaTagsForm.setFieldsValue({ meta_tags: [] });
    }
  }, [mode]);

  const cleanUpStates = () => {
    setActiveTabKey(tag);
    setMessage(addMessage);
    tagsForm.setFieldsValue({ tags: [] });
    metaTagsForm.setFieldsValue({ meta_tags: [] });
    tagsEditForm.setFieldsValue({ tags_edit: [] });
    metaTagsEditForm.setFieldsValue({ meta_tags_edit: [] });
    setItemToEdit({} as ValidDataInputType);
    setTagNamesToRemove([]);
    setTagsRemoveOptions([]);
    setMetaTagNamesToRemove([]);
    setMetaTagsRemoveOptions([]);
    setMode(BulkTagMode.add);
    setMessage(addMessage);
  };

  const handleBulkAddTags = async () => {
    try {
      setConfirmLoadingTags(true);
      const newTags = tagsForm.getFieldValue('tags');
      await Promise.all(
        currentItems.map(async (item) => {
          const { uuid, tags } = item;
          const data = tags ? { ...item, tags: [...tags, ...newTags] } : { ...item, tags: newTags };
          await onBulkSaveTags(uuid, data as ValidDataInputType);
        })
      );
    } finally {
      refreshList();
      setConfirmLoadingTags(false);
      handleClose();
    }
  };

  const handleBulkAddMetaTags = async () => {
    try {
      setConfirmLoadingMetaTags(true);
      const newMetaTags = metaTagsForm.getFieldValue('meta_tags');
      await Promise.all(
        currentItems.map(async (item) => {
          const { uuid, meta_tags } = item;
          const data = meta_tags ? [...meta_tags, ...newMetaTags] : newMetaTags;
          await onSaveMetaTags(uuid, data);
        })
      );
    } finally {
      refreshList();
      setConfirmLoadingMetaTags(false);
      handleClose();
    }
  };

  const bulkDeleteTags = async () => {
    try {
      setConfirmLoadingTags(true);
      await Promise.all(
        currentItems.map(async (item) => {
          const { uuid, tags } = item;
          if (tags !== undefined) {
            const updatedTags = tags.filter((aTag: any) => {
              const searchRes = tagNamesToRemove.find((aTagToRemove: string) => aTagToRemove === aTag.tag);
              return !searchRes;
            });
            await onBulkSaveTags(uuid, { ...item, tags: updatedTags });
          }
        })
      );
    } finally {
      setTagNamesToRemove([]);
      refreshList();
      setConfirmLoadingTags(false);
      handleClose();
    }
  };

  const bulkDeleteMetaTags = async () => {
    try {
      setConfirmLoadingMetaTags(true);
      await Promise.all(
        currentItems.map(async (item) => {
          const { uuid } = item;
          const meta_tags = item.meta_tags;
          if (meta_tags !== undefined) {
            const updatedMetaTags = meta_tags.filter((aMetaTag: any) => {
              const searchRes = metaTagNamesToRemove.find((aMetaTagName: string) => {
                const [key, value] = aMetaTagName.split('/_/');
                return key === aMetaTag.key && value === aMetaTag.value;
              });
              return !searchRes;
            });
            await onSaveMetaTags(uuid, updatedMetaTags);
          }
        })
      );
    } finally {
      setMetaTagNamesToRemove([]);
      refreshList();
      setConfirmLoadingMetaTags(false);
      handleClose();
    }
  };

  const handleBulkEditTags = async () => {
    try {
      setConfirmLoadingTags(true);
      const updatedTags = tagsEditForm.getFieldValue('tags_edit');
      await Promise.all(
        currentItems.map(async (item) => {
          const { uuid, tags } = item;
          if (tags !== undefined) {
            const updatedTagsLocal = tags.map((aTag: any) => {
              // check if the current iterating metatag has been updated
              const searchRes = updatedTags.find((anUpdatedTag: TagEditType) => {
                return anUpdatedTag.originalValue === aTag.tag;
              });
              // non-empty searchRes means this metatag has been updated
              return searchRes ? { tag: searchRes.tag } : aTag;
            });
            await onBulkSaveTags(uuid, { ...item, tags: updatedTagsLocal });
          }
        })
      );
    } finally {
      refreshList();
      setConfirmLoadingTags(false);
      handleClose();
    }
  };

  const handleBulkEditMetaTags = async () => {
    try {
      setConfirmLoadingMetaTags(true);
      const updatedMetaTags = metaTagsEditForm.getFieldValue('meta_tags_edit');
      await Promise.all(
        currentItems.map(async (item) => {
          const { uuid } = item;
          const meta_tags = item.meta_tags;
          if (meta_tags !== undefined) {
            const updatedMetaTagsLocal = meta_tags.map((aMetaTag: any) => {
              // check if the current iterating metatag has been updated
              const searchRes = updatedMetaTags.find((anUpdatedMetaTag: MetaTagEditType) => {
                const [ogKey, ogValue] = anUpdatedMetaTag.originalPair.split('/_/');
                return aMetaTag.key === ogKey && aMetaTag.value === ogValue;
              });
              // non-empty searchRes means this metatag has been updated
              if (searchRes) {
                return { ...aMetaTag, key: searchRes.key, value: searchRes.value };
              } else {
                return aMetaTag;
              }
            });
            await onSaveMetaTags(uuid, updatedMetaTagsLocal);
          }
        })
      );
    } finally {
      refreshList();
      setConfirmLoadingMetaTags(false);
      handleClose();
    }
  };

  const handleOk = async () => {
    if (mode === BulkTagMode.add) {
      activeTabKey === tag ? tagsForm.submit() : metaTagsForm.submit();
    } else if (mode === BulkTagMode.delete) {
      activeTabKey === tag ? bulkDeleteTags() : bulkDeleteMetaTags();
    } else if (mode === BulkTagMode.edit) {
      activeTabKey === tag ? handleBulkEditTags() : handleBulkEditMetaTags();
    }
  };

  const handleClose = () => {
    cleanUpStates();
    onCloseModal();
  };

  const initvalues = (currentItem: ValidDataInputType) => {
    const initTags = currentItem.tags ? currentItem.tags : [{ tag: '' }];
    const initMetaTags = currentItem.meta_tags
      ? currentItem.meta_tags
      : [
          {
            key: '',
            value: '',
          },
        ];
    tagsForm.setFieldsValue({ tags: initTags });
    metaTagsForm.setFieldsValue({ meta_tags: initMetaTags });
  };

  const handleModeChange = (value: BulkTagMode) => {
    setMode(value);
    switch (value) {
      case BulkTagMode.add:
        setMessage(addMessage);
        break;
      case BulkTagMode.delete:
        setMessage(deleteMessage);
        break;
      case BulkTagMode.edit:
        setMessage(editMessage);
        break;

      default:
        break;
    }
  };

  const TagsForm = (
    <Form
      name="tags_form"
      form={tagsForm}
      onFinish={handleBulkAddTags}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 5 }}
    >
      <Form.List name="tags">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, idx) => (
              <Space key={idx} align="baseline" style={{ paddingRight: '8px' }}>
                <Form.Item
                  {...field}
                  label="Tag"
                  name={[field.name, 'tag']}
                  rules={[{ required: true, message: 'Missing value' }]}
                  key={`tag-${idx}`}
                >
                  <Input />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Tag
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );

  const MetaTagsForm = (
    <Form
      name="meta_tags_form"
      form={metaTagsForm}
      onFinish={handleBulkAddMetaTags}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 5 }}
    >
      <Form.List name="meta_tags">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item {...restField} name={[name, 'key']} rules={[{ required: true, message: 'Missing Key' }]}>
                  <Input placeholder="key..." />
                </Form.Item>
                <Form.Item {...restField} name={[name, 'value']} rules={[{ required: true, message: 'Missing Value' }]}>
                  <Input placeholder="value..." />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Meta Tag
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );

  let tabItems: TabsProps['items'] = [];
  switch (mode) {
    case BulkTagMode.add:
      tabItems = [
        {
          key: tag,
          label: 'Tags',
          children: TagsForm,
        },
        {
          key: metaTag,
          label: 'Meta Tags',
          children: MetaTagsForm,
        },
      ];
      break;

    case BulkTagMode.edit:
      tabItems = [
        {
          key: tag,
          label: 'Tags',
          children: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 10,
                alignItems: 'flex-start',
                marginBottom: 20,
              }}
            >
              <strong>Select existing tags to edit: </strong>
              <Select
                mode="multiple"
                allowClear={true}
                placeholder="Please search and select existing tags to edit"
                style={{ width: '100%' }}
                onChange={(value: string[]) => {
                  const selectedTags = value.map((aValue: string) => {
                    return { tag: aValue, originalValue: aValue };
                  });
                  tagsEditForm.setFieldsValue({ tags_edit: selectedTags });
                }}
                options={tagsRemoveOptions}
              />
              <TagEditForm tagsEditForm={tagsEditForm} handleBulkEditTags={handleBulkEditTags} />
            </div>
          ),
        },
        {
          key: metaTag,
          label: 'Meta Tags',
          children: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 10,
                alignItems: 'flex-start',
                marginBottom: 20,
              }}
            >
              <strong>Select existing meta-tags to edit: </strong>
              <Select
                mode="multiple"
                allowClear={true}
                placeholder="Please search and select existing meta-tags to edit"
                style={{ width: '100%' }}
                onChange={(value: string[]) => {
                  const updatedKeyValuePair = value.map((aValue: string) => {
                    const [key, value] = aValue.split('/_/');
                    return { key: key, value: value, originalPair: aValue };
                  });
                  metaTagsEditForm.setFieldsValue({ meta_tags_edit: updatedKeyValuePair });
                }}
                options={metaTagsRemoveOptions}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <MetaTagEditForm metaTagsEditForm={metaTagsEditForm} handleBulkEditMetaTags={handleBulkEditMetaTags} />
              </div>
            </div>
          ),
        },
      ];
      break;

    case BulkTagMode.delete:
      tabItems = [
        {
          key: tag,
          label: 'Tags',
          children: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 5,
                alignItems: 'flex-start',
                marginBottom: 20,
              }}
            >
              <strong>Select existing tags to delete: </strong>
              <Select
                mode="multiple"
                allowClear={true}
                placeholder="Please search and select existing tags to remove"
                style={{ width: '100%' }}
                onChange={(value: string[]) => setTagNamesToRemove(value)}
                options={tagsRemoveOptions}
              />
            </div>
          ),
        },
        {
          key: metaTag,
          label: 'Meta Tags',
          children: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 5,
                alignItems: 'flex-start',
                marginBottom: 20,
              }}
            >
              <strong>Select existing meta-tags to delete: </strong>
              <Select
                mode="multiple"
                allowClear={true}
                placeholder="Please search and select existing meta-tags to remove"
                style={{ width: '100%' }}
                onChange={(value: string[]) => setMetaTagNamesToRemove(value)}
                options={metaTagsRemoveOptions}
              />
            </div>
          ),
        },
      ];
      break;

    default:
      tabItems = [];
      break;
  }

  return (
    <CModal
      title="Tags Bulk Edit"
      open={isModalVisible}
      onCancel={handleClose}
      onOk={handleOk}
      width={modalWidth}
      style={{ textAlign: 'start' }}
      styles={{ body: { minHeight: 200 } }}
      confirmLoading={confirmLoadingTags || confirmLoadingMetaTags}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: 5,
        }}
      >
        {message && (
          <AntdTag icon={<ExclamationCircleOutlined />} color="warning">
            {message}
          </AntdTag>
        )}
        <Tabs
          activeKey={activeTabKey}
          items={tabItems}
          style={{ width: '100%' }}
          onChange={(key: string) => setActiveTabKey(key)}
          tabBarExtraContent={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10,
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10,
                  width: '100%',
                }}
              >
                <strong>Mode: </strong>
                <Select
                  style={{ width: 100 }}
                  value={mode}
                  size="small"
                  onChange={handleModeChange}
                  options={[
                    { value: BulkTagMode.add, label: 'Add' },
                    { value: BulkTagMode.delete, label: 'Delete' },
                    { value: BulkTagMode.edit, label: 'Edit' },
                  ]}
                />
              </div>
            </div>
          }
        />
      </div>
    </CModal>
  );
};

export default BulkTagsModal;
