import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { isEmpty } from 'lodash';

import CModal from '../commons/c-modal/CModal';
import { generateSelectOptions } from '../utils';
import { Alerts, AlertsStatuses, Entities, Severities, Targets } from '../types';
import { useDataSource } from '../helpers/DataSourceContext';

interface CreateModalProps {
  refreshList: VoidFunction;
  onModalClose?: VoidFunction;
}

export type CreateModalRef = {
  openModal: () => void;
};

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const entityTypeOptions = generateSelectOptions(Entities);
const alertTypeOptions = generateSelectOptions(Alerts);
const statusOptions = generateSelectOptions(AlertsStatuses);
const severityOptions = generateSelectOptions(Severities);
const targetOptions = generateSelectOptions(Targets);

const CreateModal = forwardRef<CreateModalRef, CreateModalProps>(({ refreshList, onModalClose }, ref) => {
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [hostOptions, setHostOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(false);

  const { dataSource } = useDataSource();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        setIsLocationsLoading(true);
        const locations = await dataSource?.services?.locationService?.getLocationsList(true, true, true);

        const _hostOptions = locations.flatMap((location: any) =>
          (location.groups || []).flatMap((group: any) =>
            (group.hosts || []).map((host: any) => ({
              label: `${location.name} : ${group.name} : ${host.name}`,
              value: host.uuid,
            }))
          )
        );

        setHostOptions(_hostOptions);
      } finally {
        setIsLocationsLoading(false);
      }
    };

    if (!isEmpty(dataSource)) {
      void fetchLocations();
    }
  }, [dataSource]);

  const openModal = () => {
    form.setFieldsValue({
      type: Alerts[0],
      entity_type: Entities[0],
      status: AlertsStatuses[0],
      severity: Severities[0],
      target: Targets[Targets.length - 1],
      title: '',
      body: '',
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    onModalClose?.();
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleSubmit = async (item: any) => {
    try {
      setConfirmLoading(true);
      await dataSource?.services?.alertService?.create(item);
      refreshList();
      closeModal();
    } finally {
      setConfirmLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    openModal: () => {
      openModal();
    },
  }));

  return (
    <CModal
      title="Add New Alert"
      open={isModalVisible}
      confirmLoading={confirmLoading}
      onCancel={closeModal}
      onOk={form.submit}
      maskClosable={false}
    >
      <Form {...formLayout} form={form} name="control-hooks" onFinish={handleSubmit} style={{ maxWidth: '42rem' }}>
        <Form.Item name="host_uuid" label="Host" rules={[{ required: true, message: 'Host is required' }]}>
          <Select placeholder="Select a host" options={hostOptions} loading={isLocationsLoading} />
        </Form.Item>

        <Form.Item name="entity_type" label="Entity Type" rules={[{ required: true }]}>
          <Select options={entityTypeOptions} />
        </Form.Item>

        <Form.Item name="type" label="Alert Type" rules={[{ required: true }]}>
          <Select options={alertTypeOptions} />
        </Form.Item>

        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select options={statusOptions} />
        </Form.Item>

        <Form.Item name="severity" label="Severity" rules={[{ required: true }]}>
          <Select options={severityOptions} />
        </Form.Item>

        <Form.Item name="target" label="Target" rules={[{ required: true }]}>
          <Select options={targetOptions} />
        </Form.Item>

        <Form.Item name="title" label="Title">
          <Input />
        </Form.Item>

        <Form.Item name="body" label="Body">
          <Input />
        </Form.Item>
      </Form>
    </CModal>
  );
});

CreateModal.displayName = 'CreateModal';

export default CreateModal;
