import React from 'react';
import { Tooltip } from 'antd';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';

import { createMarkup } from '../utils';

type OverflowTextCellProps = {
  text: string;
  hoverText?: string;
  onClick?: VoidFunction;
};

const getStyles = () => {
  return {
    container: css({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  };
};

export const OverflowTextCell = ({ text, hoverText, onClick }: OverflowTextCellProps) => {
  const styles = useStyles2(getStyles);

  return (
    <Tooltip
      placement="topLeft"
      overlay={
        hoverText ? (
          <span>{hoverText}</span>
        ) : (
          <div
            dangerouslySetInnerHTML={createMarkup(text)}
            className={css`
              max-width: 200px;
              white-space: normal;
            `}
          />
        )
      }
    >
      <div className={styles.container} onClick={onClick}>
        {text}
      </div>
    </Tooltip>
  );
};
