import React, { useCallback } from 'react';
import { Popconfirm, Popover, Space, Spin, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

import {
  CheckCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { metaTagsSorter, tagsSorter, uuidSorter } from '../utils';
import { OverflowTextCell } from '../components/OverflowTextCell';
import { MetaTagsRenderer, TagsRenderer } from '../commons/renderer/Renderer';
import { useTeamsStore } from './useTeamsStore';
import { useDataSource } from '../helpers/DataSourceContext';

const ALERT_HEADERS = [
  {
    title: 'UUID',
    dataIndex: 'uuid',
    key: 'uuid',
    width: 200,
    sorter: uuidSorter,
  },
  {
    title: 'Host UUID',
    dataIndex: 'host_uuid',
    key: 'host_uuid',
    width: 200,
    sorter: (a: any, b: any) => a.host_uuid.localeCompare(b.host_uuid),
  },
  {
    title: 'Entity Type',
    dataIndex: 'entity_type',
    key: 'entity_type',
    width: 200,
    sorter: (a: any, b: any) => a.entity_type.localeCompare(b.entity_type),
  },
  {
    title: 'Entity UUID',
    dataIndex: 'entity_uuid',
    key: 'entity_uuid',
    width: 200,
    render: (entity_uuid: string) => {
      return (
        <div className="flex flex-row items-center justify-center">
          <span>{entity_uuid ?? 'N/A'}</span>
        </div>
      );
    },
    sorter: (a: any, b: any) => (a.entity_uuid ?? '').localeCompare(b.entity_uuid ?? ''),
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    width: 200,
    render: (source: string) => {
      return (
        <div className="flex flex-row items-center justify-center">
          <span>{source ?? 'N/A'}</span>
        </div>
      );
    },
    sorter: (a: any, b: any) => a.source.localeCompare(b.source),
  },
  {
    title: 'Acknowledge Timeout',
    dataIndex: 'acknowledge_timeout',
    key: 'acknowledge_timeout',
    width: 280,
    render: (acknowledge_timeout: string) => {
      return (
        <div className="flex flex-row items-center justify-center">
          <span>
            {acknowledge_timeout ? dayjs(acknowledge_timeout).local().format('hh:mm:ss, Do MMMM YYYY') : 'N/A'}
          </span>
        </div>
      );
    },
    sorter: (a: any, b: any) => (a.acknowledge_timeout ?? '').localeCompare(b.acknowledge_timeout ?? ''),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 200,
    sorter: (a: any, b: any) => a.type.localeCompare(b.type),
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    width: 200,
    sorter: (a: any, b: any) => a.status.localeCompare(b.status),
    render: (status: string) => {
      let color = 'red';
      let icon = <QuestionCircleOutlined />;
      switch (status.toLowerCase()) {
        case 'active':
          color = 'red';
          icon = <QuestionCircleOutlined />;
          break;
        case 'acknowledged':
          color = 'orange';
          icon = <ExclamationCircleOutlined />;
          break;
        case 'closed':
          color = 'green';
          icon = <CheckCircleOutlined />;
          break;

        default:
          break;
      }
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Tag color={color} icon={icon}>
            {status.toUpperCase()}
          </Tag>
        </div>
      );
    },
  },
  {
    title: 'Severity',
    dataIndex: 'severity',
    key: 'severity',
    width: 200,
    sorter: (a: any, b: any) => a.severity.localeCompare(b.severity),
  },
  {
    title: 'Target',
    dataIndex: 'target',
    key: 'target',
    width: 200,
    sorter: (a: any, b: any) => (a.target ?? '').localeCompare(b.target ?? ''),
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: 200,
    sorter: (a: any, b: any) => (a.title ?? '').localeCompare(b.title ?? ''),
  },
  {
    title: 'Body',
    dataIndex: 'body',
    key: 'body',
    width: 300,
    onCell: () => ({
      style: {
        whitespace: 'nowrap',
        maxWidth: 300,
      },
    }),
    render: (text: string) => <OverflowTextCell text={text} />,
    sorter: (a: any, b: any) => (a.body ?? '').localeCompare(b.body ?? ''),
  },
  {
    title: 'Notified',
    dataIndex: 'notified',
    key: 'notified',
    width: 200,
    sorter: (a: any, b: any) => (a.notified ?? 0) - (b.notified ?? 0),
    render: (notified: any) => {
      let colour = 'blue';
      let text = 'Unnotified';
      if (notified) {
        colour = 'orange';
        text = 'Notified';
      }
      return <Tag color={colour}>{text}</Tag>;
    },
  },
  {
    title: 'Notified At',
    dataIndex: 'notified_at',
    key: 'notified_at',
    width: 280,
    sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
    render: (notifiedAt: string) => <span>{dayjs(notifiedAt).format('HH:mm:ss, Do MMMM YYYY')}</span>,
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 280,
    sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
    render: (createdAt: string) => <span>{dayjs(createdAt).format('HH:mm:ss, Do MMMM YYYY')}</span>,
  },
  {
    title: 'Last updated',
    dataIndex: 'last_updated',
    key: 'last_updated',
    width: 280,
    sorter: (a: any, b: any) => a.last_updated.localeCompare(b.last_updated),
    render: (lastUpdated: string) => <span>{dayjs(lastUpdated).format('HH:mm:ss, Do MMMM YYYY')}</span>,
  },
  {
    key: 'tags',
    title: 'Tags',
    dataIndex: 'tags',
    width: 200,
    render: TagsRenderer,
    sorter: tagsSorter,
  },
  {
    key: 'meta_tags',
    title: 'Meta Tags',
    dataIndex: 'meta_tags',
    width: 200,
    render: MetaTagsRenderer,
    sorter: metaTagsSorter,
  },
];

const renderTickets = (tickets: any, record: any) => {
  if (!tickets) {
    return '';
  }

  return (
    <>
      <Popover
        content={
          <Space size={5} wrap style={{ width: 400 }}>
            {tickets.map(({ subject, status }: { subject: any; status: any }, index: number) => (
              <Tag color={'cyan'} key={index} style={{ marginRight: '0.25rem' }}>
                {subject}: {status}
              </Tag>
            ))}
          </Space>
        }
        title={`Tickets of: ${record?.title || 'N/A'}`}
      >
        <div>
          {tickets.length !== 0 && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
              <Tag
                color={'cyan'}
                style={{
                  maxWidth: '150px',
                  marginRight: '0.25rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {tickets[0].subject}: {tickets[0].status}
              </Tag>

              {tickets.length > 1 && <strong>{'...'}</strong>}
            </div>
          )}
        </div>
      </Popover>
    </>
  );
};

const renderTransactions = (transactions: any, record: any) => {
  if (!transactions) {
    return '';
  }

  const maxNumOfItemsRendered = 20;

  const statusToColor = (status: string | undefined) => {
    if (status === undefined) {
      return 'cyan';
    }

    switch (status) {
      case 'active':
        return 'red';
      case 'acknowledged':
        return 'orange';
      case 'closed':
        return 'green';
      default:
        return 'cyan';
    }
  };

  return (
    <>
      <Popover
        content={
          <Space size={5} wrap style={{ width: 400 }}>
            {(transactions.length <= maxNumOfItemsRendered
              ? transactions
              : transactions.slice(0, maxNumOfItemsRendered)
            ).map(({ title, status }: { title: any; status: any }, index: number) => (
              <Tag color={statusToColor(status)} key={index} style={{ marginRight: '0.25rem' }}>
                {title}: {status}
              </Tag>
            ))}

            {transactions.length > maxNumOfItemsRendered && <strong>{'...'}</strong>}
          </Space>
        }
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <span>{`Transactions of: ${record?.title || 'N/A'}`}</span>

            {transactions.length > maxNumOfItemsRendered && (
              <Tag color="warning" icon={<ExclamationCircleOutlined />}>
                {`more than ${maxNumOfItemsRendered} transactions, use the alerts table to check the rest...`}
              </Tag>
            )}
          </div>
        }
      >
        <div>
          {transactions.length !== 0 && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
              <Tag
                color={statusToColor(transactions[0].status)}
                style={{
                  maxWidth: '150px',
                  marginRight: '0.25rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {transactions[0].title}: {transactions[0].status}
              </Tag>
              {transactions.length > 1 && <strong>{'...'}</strong>}
            </div>
          )}
        </div>
      </Popover>
    </>
  );
};

const TeamsCell = ({ alertTeams }: { alertTeams?: any }) => {
  const { teams, isTeamsLoading } = useTeamsStore();

  if (isTeamsLoading) {
    return <Spin size="small" />;
  }

  if (!alertTeams) {
    return null;
  }

  return (
    <Popover
      content={
        <Space size={5} wrap style={{ width: 400 }}>
          {alertTeams.map((alertTeam: any) => (
            <Tag color="blue" key={alertTeam.team_uuid}>
              {teams.find((team) => alertTeam.team_uuid === team.uuid)?.name}
            </Tag>
          ))}
        </Space>
      }
      title={`Teams`}
    >
      <div>
        {alertTeams.length !== 0 && (
          <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
            <Tag
              style={{
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              color="blue"
            >
              {teams.find((team) => alertTeams[0].team_uuid === team.uuid)?.name}
            </Tag>

            {alertTeams.length > 1 && <strong>{'...'}</strong>}
          </div>
        )}
      </div>
    </Popover>
  );
};

const RowActions = ({
  alert,
  setSelectedAlertUUID,
  refreshList,
}: {
  alert: any;
  setSelectedAlertUUID: (alertUUID: string) => void;
  refreshList: VoidFunction;
}) => {
  const { dataSource } = useDataSource();

  const deleteAlert = useCallback(async () => {
    try {
      await dataSource?.services?.alertService?.removeById(alert.uuid);
      refreshList();
    } catch (error) {
      console.log('deleteAlert-failed', error);
    }
  }, [alert.uuid]);

  return (
    <Space size="middle">
      <Popconfirm title="Are you sure to delete?" onConfirm={deleteAlert}>
        <a key="list-delete">
          <DeleteOutlined style={{ color: 'red' }} />
        </a>
      </Popconfirm>

      <Tooltip title="Create ticket for this alert">
        <a
          onClick={(e) => {
            e?.stopPropagation();
            setSelectedAlertUUID(alert.uuid);
          }}
        >
          <ProfileOutlined />
        </a>
      </Tooltip>
    </Space>
  );
};

const useAlertsTableColumns = (
  setSelectedAlertUUID: (alertUUID: string) => void,
  refreshList: VoidFunction,
  selectedCols: string[],
  fixedCols: string[]
) => {
  const actionsColumn = {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: 100,
    render: (_: any, item: any) => (
      <RowActions alert={item} setSelectedAlertUUID={setSelectedAlertUUID} refreshList={refreshList} />
    ),
  };
  const teamsColumn = {
    title: 'Teams',
    key: 'teams',
    dataIndex: 'teams',
    width: 250,
    render: (alertTeams?: any) => {
      return <TeamsCell alertTeams={alertTeams} />;
    },
  };
  const transactionsColumn = {
    key: 'transactions',
    title: 'Transactions',
    dataIndex: 'transactions',
    width: 250,
    render(transactions: any[], rowData: any) {
      return renderTransactions(transactions, rowData);
    },
  };
  const ticketsColumn = {
    key: 'tickets',
    title: 'Tickets',
    dataIndex: 'tickets',
    width: 250,
    render(tickets: any[], rowData: any) {
      return renderTickets(tickets, rowData);
    },
  };

  let column: any[] = [];
  let fixedColMap: any = {};
  let reorderedFixedCols: any[] = [];
  if (selectedCols.length === 0) {
    column = [actionsColumn, ...ALERT_HEADERS, teamsColumn];
  } else {
    selectedCols.forEach((aColName: string) => {
      if (aColName === 'actions') {
        fixedCols.includes('actions')
          ? (fixedColMap['actions'] = { ...actionsColumn, fixed: 'left' })
          : column.push(actionsColumn);
      } else if (aColName === 'teams') {
        fixedCols.includes('teams')
          ? (fixedColMap['teams'] = { ...teamsColumn, fixed: 'left' })
          : column.push(teamsColumn);
      } else if (aColName === 'transactions') {
        fixedCols.includes('transactions')
          ? (fixedColMap['transactions'] = { ...transactionsColumn, fixed: 'left' })
          : column.push(transactionsColumn);
      } else if (aColName === 'tickets') {
        fixedCols.includes('tickets')
          ? (fixedColMap['tickets'] = { ...ticketsColumn, fixed: 'left' })
          : column.push(ticketsColumn);
      } else {
        const findRes = ALERT_HEADERS.find((aHeader: any) => aHeader.dataIndex === aColName);
        findRes && fixedCols.includes(aColName)
          ? (fixedColMap[aColName] = { ...findRes, fixed: 'left' })
          : column.push(findRes);
      }
    });

    fixedCols.forEach((aColName: string) => {
      reorderedFixedCols.push(fixedColMap[aColName]);
    });
  }

  return { tableColumns: [...reorderedFixedCols, ...column] };
};

export { useAlertsTableColumns };
