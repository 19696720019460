import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';

interface DataSourceContextType {
  dataSource: any;
  setDataSource: Dispatch<SetStateAction<any>>;
}

interface DataSourceProviderProps {
  children: ReactNode;
}

const DataSourceContext = createContext<DataSourceContextType | undefined>(undefined);

const DataSourceProvider: FC<DataSourceProviderProps> = ({ children }) => {
  const [dataSource, setDataSource] = useState<any>({});

  return <DataSourceContext.Provider value={{ dataSource, setDataSource }}>{children}</DataSourceContext.Provider>;
};

const useDataSource = () => {
  const context = useContext(DataSourceContext);
  if (!context) {
    throw new Error('useDataSource must be used within a DataSourceProvider');
  }
  return context;
};

export { DataSourceProvider, useDataSource };
