import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';

import CModal from '../commons/c-modal/CModal';
import { generateSelectOptions } from '../utils';
import { TicketPriorityTypes, TicketStatusTypes } from '../types';
import { useDataSource } from '../helpers/DataSourceContext';

interface CreateTicketModalProps {
  onModalClose?: VoidFunction;
  isEdit?: boolean;
  alertUUID?: string;
}

export type CreateTicketModalRef = {
  openModal: () => void;
};

const getStyles = () => {
  return {
    formContainer: css({
      maxWidth: '42rem',
    }),
  };
};

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ticketPriorityOptions = generateSelectOptions(TicketPriorityTypes);
const ticketStatusOptions = generateSelectOptions(TicketStatusTypes);

const CreateTicketModal = forwardRef<CreateTicketModalRef, CreateTicketModalProps>(
  ({ onModalClose, isEdit, alertUUID }, ref) => {
    const [form] = Form.useForm();

    const [isModalVisible, setIsModalOpen] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const { dataSource } = useDataSource();

    const openModal = () => {
      form.setFieldsValue({
        uuid: '',
        alert_uuid: alertUUID,
        issuer: '',
        subject: '',
        content: '',
        priority: TicketPriorityTypes[0],
        status: TicketStatusTypes[0],
      });
      setIsModalOpen(true);
    };

    const closeModal = () => {
      onModalClose?.();
      form.resetFields();
      setIsModalOpen(false);
    };

    const handleSubmit = async (item: any) => {
      try {
        setConfirmLoading(true);
        await dataSource?.services?.ticketService?.create(item);
        closeModal();
      } finally {
        setConfirmLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      openModal: () => {
        openModal();
      },
    }));

    const styles = useStyles2(getStyles);

    return (
      <CModal
        title="Add New Ticket"
        open={isModalVisible}
        confirmLoading={confirmLoading}
        onCancel={closeModal}
        onOk={form.submit}
        maskClosable={false}
      >
        <Form {...formLayout} form={form} name="control-hooks" onFinish={handleSubmit} className={styles.formContainer}>
          <Form.Item name="alert_uuid" label="Alert UUID" hidden={!alertUUID}>
            <Input disabled />
          </Form.Item>

          <Form.Item name="issuer" label="Issuer" hidden>
            <Input disabled />
          </Form.Item>

          <Form.Item name="subject" label="Subject" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="content" label="Content" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="priority" label="Priority" rules={[{ required: true }]} hidden={isEdit}>
            <Select options={ticketPriorityOptions} />
          </Form.Item>

          <Form.Item name="status" label="Status" rules={[{ required: true }]} hidden={isEdit}>
            <Select options={ticketStatusOptions} />
          </Form.Item>
        </Form>
      </CModal>
    );
  }
);

CreateTicketModal.displayName = 'CreateTicketModal';

export default CreateTicketModal;
