import React, { CSSProperties, useRef } from 'react';
import { Divider } from 'antd';

import { ContextMenuData, ContextMenuItem } from './types';
import { calculateTopAndLeft } from '../../utils';
import { css } from '@emotion/css';
import { useStyles2, useTheme2 } from '@grafana/ui';

type TableContentMenuProps<T> = {
  isVisible: boolean;
  contextMenuData?: ContextMenuData<T>;
  contextMenuItems: ContextMenuItem[];
};

const getStyles = () => {
  return {
    dividerContainer: css({
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    }),
    divider: css({
      minWidth: 0,
      marginTop: '0.25rem',
      marginBottom: '0.25rem',
    }),
  };
};

const ContextMenu = <T,>({ contextMenuData, contextMenuItems, isVisible }: TableContentMenuProps<T>) => {
  const contextMenuRef = useRef<HTMLUListElement>(null);
  const theme = useTheme2();

  const { xPos, yPos } = contextMenuData ?? { xPos: 0, yPos: 0 };

  const menu = contextMenuRef.current;
  const { top, left } = calculateTopAndLeft(menu, xPos, yPos);

  const contextStyle: CSSProperties = {
    position: 'fixed',
    visibility: isVisible ? 'visible' : 'hidden',
    border: '1px solid #555555',
    zIndex: 10,
    top,
    left,
    width: 210,
    paddingTop: 3,
    paddingBottom: 3,
    borderRadius: 6,
    backgroundColor: theme.isDark ? '#1f1f1f' : '#f0f2f5',
    color: theme.isDark ? '#f0f2f5' : '#1f1f1f',
    textAlign: 'start',
    listStyle: 'none',
  };

  const styles = useStyles2(getStyles);

  return (
    <>
      <ul style={contextStyle} ref={contextMenuRef}>
        {contextMenuItems.map((menuItem, index) => {
          if (menuItem === 'divider') {
            return (
              <div key={index} className={styles.dividerContainer}>
                <Divider className={styles.divider} />
              </div>
            );
          }

          if (menuItem.isHidden) {
            return null;
          }

          return (
            <li
              className={css`
                border-radius: 6px;
                margin: 2px 4px;
                padding: 2px 6px;
                cursor: pointer;

                &:hover {
                  background-color: rgba(0, 0, 0, 0.08);
                }
              `}
              key={menuItem.label}
              onClick={menuItem.onClick}
              style={menuItem.style}
            >
              {menuItem.Icon} {menuItem.label}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ContextMenu;
