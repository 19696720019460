import React, { FC } from 'react';
import { Badge, Button, Input, Row, Select, Tooltip } from 'antd';
import {
  CalendarOutlined,
  DeleteOutlined,
  FilterOutlined,
  PlusOutlined,
  ReloadOutlined,
  SplitCellsOutlined,
} from '@ant-design/icons';
import { GrafanaTheme2 } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { AlertsStatuses, AlertsStatusesType } from '../types';
import { generateSelectOptions } from '../utils';

interface TableHeaderProps {
  data: any[];
  search: string;
  setSearch: (input: string) => void;
  hostUUID: string;
  onRefresh: VoidFunction;
  onCreateAlert: VoidFunction;
  bulkDelete: VoidFunction;
  onFCMServerClick: VoidFunction;
  selectedAlertStatuses: AlertsStatusesType[];
  setSelectedAlertStatuses: (alertStatuses: AlertsStatusesType[]) => void;
  onColReorganize: VoidFunction;
  onAdvanceSearch: VoidFunction;
  isAdvanceSearchActive: boolean;
  onFilterAlert: VoidFunction;
  isBadgeVisible?: boolean;
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css({
      width: '100%',
      justifyContent: 'space-between',
    }),
    leftContainer: css({
      columnGap: '0.5rem',
    }),
    rightContainer: css({
      columnGap: '0.5rem',
    }),
    button: css({
      margin: '0 0 0.5rem 0',
    }),
    searchInput: css({
      width: '500px',
      marginBottom: '0.5rem',
    }),
    select: css({
      marginBottom: '0.5rem',
      minWidth: '180px',
    }),
    zeroRightMargin: css({
      marginRight: 0,
    }),
  };
};

const alertsStatusOptions = generateSelectOptions(AlertsStatuses);

const TableHeader: FC<TableHeaderProps> = ({
  data,
  search,
  setSearch,
  hostUUID,
  onRefresh,
  onCreateAlert,
  bulkDelete,
  onFCMServerClick,
  selectedAlertStatuses,
  setSelectedAlertStatuses,
  onColReorganize,
  onAdvanceSearch,
  isAdvanceSearchActive,
  onFilterAlert,
  isBadgeVisible = false,
}) => {
  const styles = useStyles2(getStyles);
  const isEditing = locationService.getSearchObject().editPanel !== undefined;

  return (
    <Row className={styles.container} id="alert-table-header">
      <Row className={styles.leftContainer}>
        <Tooltip title="refresh">
          <Button className={styles.button} icon={<ReloadOutlined />} onClick={onRefresh} />
        </Tooltip>

        <Tooltip title="create new alert">
          <Button className={styles.button} icon={<PlusOutlined />} onClick={onCreateAlert} />
        </Tooltip>

        {hostUUID && (
          <Tooltip title="delete">
            <div className="w-10">
              <Button className={styles.button} icon={<DeleteOutlined />} onClick={bulkDelete}>
                Delete
              </Button>
            </div>
          </Tooltip>
        )}

        <Tooltip title="reorganise columns">
          <Button className={styles.button} onClick={onColReorganize} icon={<SplitCellsOutlined />} />
        </Tooltip>

        <Input
          value={search}
          className={styles.searchInput}
          onChange={(e) => setSearch(e.target.value)}
          disabled={data !== undefined ? data?.length === 0 : true}
          placeholder="Search by title or body..."
        />

        <Tooltip title="filter by time">
          <Button
            className={styles.button}
            type={isAdvanceSearchActive ? 'primary' : 'default'}
            onClick={onAdvanceSearch}
            icon={<CalendarOutlined />}
          />
        </Tooltip>

        <Select
          mode="multiple"
          showSearch={false}
          className={styles.select}
          placeholder="Filter status"
          defaultValue={selectedAlertStatuses}
          onChange={setSelectedAlertStatuses}
          options={alertsStatusOptions}
        />
      </Row>

      <Row className={styles.rightContainer}>
        {!isEditing && (
          <Tooltip title="apply alert filters">
            <Badge dot={isBadgeVisible}>
              <Button className={styles.button} icon={<FilterOutlined />} onClick={onFilterAlert} />
            </Badge>
          </Tooltip>
        )}

        <Button className={styles.button} onClick={onFCMServerClick}>
          FCM Server
        </Button>
      </Row>
    </Row>
  );
};

export default TableHeader;
