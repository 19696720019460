import React, { useCallback, useEffect, useState } from 'react';
import { Form, Select } from 'antd';

import CModal from '../commons/c-modal/CModal';
import { useTeamsStore } from '../hooks/useTeamsStore';
import { useDataSource } from '../helpers/DataSourceContext';

interface BulkAssignTeamsModalProps {
  isModalVisible: boolean;
  onCloseModal: VoidFunction;
  refreshList: (params: { editedRowId?: string }) => void;
  alertUUIDs: string[];
}

type AssignTeamsFormType = {
  teams: string[];
};

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const BulkAssignTeamsModal = ({ isModalVisible, onCloseModal, alertUUIDs, refreshList }: BulkAssignTeamsModalProps) => {
  const [teamsOptions, setTeamsOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm<AssignTeamsFormType>();

  const { teams, isTeamsLoading } = useTeamsStore();
  const { dataSource } = useDataSource();

  const fetchTeamsOptions = useCallback(async () => {
    try {
      const _teamsOptions = teams.map((team) => ({
        label: team.name,
        value: team.uuid,
      }));

      setTeamsOptions(_teamsOptions);
    } finally {
    }
  }, [teams, setTeamsOptions]);

  useEffect(() => {
    void fetchTeamsOptions();
  }, [teams]);

  const handleClose = useCallback(() => {
    form.resetFields();
    onCloseModal();
  }, []);

  const assignATeams = useCallback(async (uuid: string, teams: string[]) => {
    try {
      return await dataSource?.services?.alertService?.assignAlertTeams(uuid, teams);
    } catch {}
  }, []);

  const handleSubmit = useCallback(
    async (item: any) => {
      try {
        setConfirmLoading(true);
        await Promise.all(
          alertUUIDs.map(async (uuid: string) => {
            return await assignATeams(uuid, item.teams);
          })
        );
        refreshList({});
        handleClose();
      } finally {
        setConfirmLoading(false);
      }
    },
    [alertUUIDs]
  );

  return (
    <CModal
      title="Bulk assign teams"
      open={isModalVisible}
      confirmLoading={confirmLoading}
      onOk={form.submit}
      onCancel={handleClose}
      style={{ textAlign: 'start' }}
      maskClosable={false}
    >
      <Form {...formLayout} form={form} name="control-hooks" onFinish={handleSubmit} style={{ maxWidth: '42rem' }}>
        <Form.Item name="teams" label="Teams">
          <Select options={teamsOptions} placeholder="Select Teams" loading={isTeamsLoading} mode="multiple" />
        </Form.Item>
      </Form>
    </CModal>
  );
};

export default BulkAssignTeamsModal;
