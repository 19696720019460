import { PanelData } from '@grafana/data';

import { AlertKeys, DataFieldKeyI } from './types';
import { get } from 'lodash';
import DOMPurify from 'dompurify';

const dataFieldKeys: DataFieldKeyI = {
  TIME: '0',
  CONFIG: '1',
  PRIORITY: '2',
};

const generateSelectOptions = (items: readonly string[]) =>
  items.map((item) => ({
    value: item,
    label: item,
  }));

const calculateTopAndLeft = (menu: HTMLUListElement | null, xPos: number, yPos: number) => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  const menuWidth = menu?.offsetWidth ?? 0;
  const menuHeight = menu?.offsetHeight ?? 0;
  const overflowRight = xPos + menuWidth > viewportWidth;
  const overflowBottom = yPos + menuHeight > viewportHeight;
  const adjustedYPos = yPos - menuHeight;

  const top = overflowBottom ? Math.max(adjustedYPos, 0) : yPos;
  const left = overflowRight ? xPos - menuWidth : xPos;

  return { top, left };
};

const getFieldValue = (fieldKey: string, data: PanelData): any => {
  const series = get(data, 'series', []);
  if (!series.length) {
    return null;
  }
  const latestIdx = series.length - 1;
  if (!fieldKey) {
    throw new Error('Data field key is required');
  }

  return get(series, `${latestIdx}.fields[${Number(fieldKey)}].values[0]`, null);
};

const uuidSorter = (a: { uuid: string }, b: { uuid: string }) => a.uuid.localeCompare(b.uuid);

const tagsSorter = (a: any, b: any) => {
  const tagsA = (a.tags ?? []).map((o: any) => o.tag).join('');
  const tagsB = (b.tags ?? []).map((o: any) => o.tag).join('');
  return tagsA.localeCompare(tagsB);
};

const metaTagsSorter = (a: any, b: any) => {
  const mtA = (a.meta_tags ?? []).map((o: any) => (o?.key ?? '')?.concat(o?.value ?? '')).join('');
  const mtB = (b.meta_tags ?? []).map((o: any) => (o?.key ?? '')?.concat(o?.value ?? '')).join('');
  return mtA.localeCompare(mtB);
};

const getAlertSchema = (): AlertKeys[] => {
  return [
    'uuid',
    'host_uuid',
    'entity_type',
    'entity_uuid',
    'type',
    'status',
    'severity',
    'target',
    'title',
    'body',
    'source',
    'notified',
    'notified_at',
    'emailed',
    'emailed_at',
    'created_at',
    'last_updated',
    'tags',
    'meta_tags',
    'tickets',
    'teams',
    'transactions',
  ];
};

const createMarkup = (dirty: string) => {
  return { __html: DOMPurify.sanitize(dirty) };
};

export {
  generateSelectOptions,
  calculateTopAndLeft,
  dataFieldKeys,
  getFieldValue,
  uuidSorter,
  tagsSorter,
  metaTagsSorter,
  getAlertSchema,
  createMarkup,
};
