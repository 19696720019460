import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Space, Tabs, TabsProps } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import CModal from '../commons/c-modal/CModal';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';

interface TagsModalProps {
  isModalVisible: boolean;
  isShowMetaTags?: boolean;
  currentItem: any;
  onSaveTags: Function;
  onSaveMetaTags?: Function;
  onCloseModal: VoidFunction;
  refreshList: Function;
}

const getStyles = () => {
  return {
    btnContainer: css({
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      columnGap: '10px',
    }),
  };
};

const TagsModal = ({
  isModalVisible,
  currentItem,
  onSaveTags,
  onSaveMetaTags,
  onCloseModal,
  refreshList,
  isShowMetaTags = true,
}: TagsModalProps) => {
  const [confirmLoadingTags, setConfirmLoadingTags] = useState(false);
  const [confirmLoadingMetaTags, setConfirmLoadingMetaTags] = useState(false);
  const [tagsForm] = Form.useForm();
  const [metaTagsForm] = Form.useForm();

  const styles = useStyles2(getStyles);

  const initializeValues = () => {
    const initTags = currentItem.tags ? currentItem.tags : [{ tag: '' }];
    const initMetaTags = currentItem.meta_tags
      ? currentItem.meta_tags
      : [
          {
            key: '',
            value: '',
          },
        ];
    tagsForm.setFieldsValue({ tags: initTags });
    metaTagsForm.setFieldsValue({ meta_tags: initMetaTags });
  };

  useEffect(() => {
    if (isModalVisible) {
      initializeValues();
    }
  }, [isModalVisible, initializeValues]);

  const handleSubmitTags = async () => {
    try {
      setConfirmLoadingTags(true);
      const tags = tagsForm.getFieldValue('tags');
      await onSaveTags(tags);
      refreshList();
    } finally {
      setConfirmLoadingTags(false);
      handleClose();
    }
  };

  const handleSubmitMetaTags = async () => {
    if (onSaveMetaTags) {
      try {
        setConfirmLoadingMetaTags(true);
        const meta_tags = metaTagsForm.getFieldValue('meta_tags');
        await onSaveMetaTags(meta_tags);
        refreshList();
      } finally {
        setConfirmLoadingMetaTags(false);
        handleClose();
      }
    }
  };

  const handleClose = () => {
    onCloseModal();
  };

  const TagsForm = (
    <Form name="tags_form" form={tagsForm} onFinish={handleSubmitTags} style={{ textAlign: 'center' }}>
      <Form.List name="tags">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, idx) => (
              <Space key={idx} align="baseline" style={{ display: 'flex', justifyContent: 'center' }}>
                <Form.Item
                  {...field}
                  label="Tag"
                  name={[field.name, 'tag']}
                  rules={[{ required: true, message: 'Missing value' }]}
                  key={`tag-${idx}`}
                >
                  <Input />
                </Form.Item>

                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}

            <Form.Item>
              <Button type="dashed" onClick={add} block icon={<PlusOutlined />}>
                Add Tag
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item>
        <div className={styles.btnContainer}>
          <Button onClick={handleClose}>Close</Button>

          <Button type="primary" htmlType="submit" loading={confirmLoadingTags}>
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );

  const MetaTagsForm = (
    <Form name="meta_tags_form" form={metaTagsForm} onFinish={handleSubmitMetaTags} style={{ textAlign: 'center' }}>
      <Form.List name="meta_tags">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item {...restField} name={[name, 'key']} rules={[{ required: true, message: 'Missing Key' }]}>
                    <Input placeholder="key..." />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    rules={[{ required: true, message: 'Missing Value' }]}
                  >
                    <Input placeholder="value..." />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}

              <Form.Item style={{ width: '100%' }}>
                <Button style={{ width: '100%' }} type="dashed" onClick={add} block icon={<PlusOutlined />}>
                  Add Meta Tag
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>

      <Form.Item style={{ width: '100%' }}>
        <div className={styles.btnContainer}>
          <Button onClick={handleClose}>Close</Button>

          <Button type="primary" htmlType="submit" loading={confirmLoadingMetaTags}>
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );

  const tabItems: TabsProps['items'] = [
    {
      key: 'tags',
      label: 'Tags',
      children: TagsForm,
    },
    {
      key: 'meta_tags',
      label: 'Meta Tags',
      children: MetaTagsForm,
    },
  ];

  return (
    <CModal
      title={isShowMetaTags ? null : 'Tags'}
      open={isModalVisible}
      footer={null}
      onCancel={handleClose}
      width={'30vw'}
      style={{ textAlign: 'start' }}
    >
      {isShowMetaTags ? <Tabs defaultActiveKey="tags" items={tabItems} /> : <>{TagsForm}</>}
    </CModal>
  );
};

export default TagsModal;
