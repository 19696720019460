import React from 'react';
import { useAlertsContextMenuStore } from '../hooks/useAlertsContextMenuStore';
import UpdateStatusModal from './UpdateStatusModal';
import AssignTeamsModal from './AssignTeamsModal';
import TagsModal from './TagsModal';
import ExportModal from './ExportModal';
import BulkUpdateStatusModal from './BulkUpdateStatusModal';
import BulkAssignTeamsModal from './BulkAssignTeamsModal';
import BulkTagsModal from './BulkTagsModal';

interface ContextMenuModalProps {
  refreshList: (params: { editedRowId?: string }) => void;
  selectedAlertUUIDs: string[];
  selectedAlerts: any[];
  handleSaveTags: any;
  handleSaveMetaTags: any;
  clearSelection: () => void;
}

const ContextMenuModals = ({
  refreshList,
  selectedAlertUUIDs,
  selectedAlerts,
  handleSaveTags,
  handleSaveMetaTags,
  clearSelection,
}: ContextMenuModalProps) => {
  const { selectedAlert, ...contentMenuStore } = useAlertsContextMenuStore();

  if (!selectedAlert) {
    return null;
  }

  return (
    <>
      <UpdateStatusModal
        isModalVisible={contentMenuStore.isUpdateStatusModalVisible}
        onCloseModal={() => contentMenuStore.setIsUpdateStatusModelVisible(false)}
        refreshList={refreshList}
        alert={selectedAlert}
      />

      <AssignTeamsModal
        isModalVisible={contentMenuStore.isAssignTeamsModalVisible}
        onCloseModal={() => contentMenuStore.setIsAssignTeamsModalVisible(false)}
        refreshList={refreshList}
        alert={selectedAlert}
      />

      <TagsModal
        isModalVisible={contentMenuStore.isTagModalVisible}
        currentItem={selectedAlert}
        onSaveTags={async (_tags: any) => {
          await handleSaveTags(selectedAlert.uuid, { ...selectedAlert, tags: _tags });
        }}
        onSaveMetaTags={async (_meta_tags: any) => {
          await handleSaveMetaTags(selectedAlert.uuid, _meta_tags);
        }}
        onCloseModal={() => {
          contentMenuStore.setIsTagModalVisible(false);
        }}
        refreshList={refreshList}
      />

      <ExportModal
        alerts={selectedAlerts}
        isModalVisible={contentMenuStore.isExportModalVisible}
        onCloseModal={() => {
          contentMenuStore.setIsExportModalVisible(false);
          clearSelection();
        }}
      />

      <BulkUpdateStatusModal
        isModalVisible={contentMenuStore.isBulkUpdateStatusModalVisible}
        onCloseModal={() => {
          contentMenuStore.setIsBulkUpdateStatusModelVisible(false);
          clearSelection();
        }}
        refreshList={refreshList}
        alertUUIDs={selectedAlertUUIDs}
      />

      <BulkAssignTeamsModal
        isModalVisible={contentMenuStore.isBulkAssignTeamsModalVisible}
        onCloseModal={() => {
          contentMenuStore.setIsBulkAssignTeamsModalVisible(false);
          clearSelection();
        }}
        refreshList={refreshList}
        alertUUIDs={selectedAlertUUIDs}
      />

      <BulkTagsModal
        isModalVisible={contentMenuStore.isBulkTagModalVisible}
        currentItems={selectedAlerts}
        onBulkSaveTags={handleSaveTags}
        onSaveMetaTags={handleSaveMetaTags}
        onCloseModal={() => {
          contentMenuStore.setIsBulkTagModalVisible(false);
          clearSelection();
        }}
        refreshList={refreshList}
      />
    </>
  );
};

export default ContextMenuModals;
