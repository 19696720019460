import React, { useEffect, useState } from 'react';
import { Input, Tooltip } from 'antd';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import * as XLSX from 'xlsx';

import CModal from '../commons/c-modal/CModal';

interface ExportModalProps {
  alerts: any;
  isModalVisible: boolean;
  onCloseModal: VoidFunction;
}

const getStyles = () => {
  return {
    container: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      rowGap: '5px',
    }),
    inputContainer: css({
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: '5px',
    }),
  };
};

const coordinateToCellName = (coordinate: [number, number]) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const row = coordinate[0];
  const column = coordinate[1];

  let cellName = '';

  // Convert column number to letters (A, B, C, ..., Z, AA, AB, AC, ...)
  let quotient = column;
  while (quotient > 0) {
    const remainder = (quotient - 1) % 26;
    cellName = alphabet[remainder] + cellName;
    quotient = Math.floor((quotient - 1) / 26);
  }

  return cellName + row;
};

const reformatAlertObj = (alerts: any) => {
  if (alerts.length !== 0) {
    // check all alert objects and get the union of their keys and use those as columns for the excel sheet
    let columns: string[] = [];
    Object.keys(alerts[0]).forEach((aKey: string) => {
      !columns.includes(aKey) && columns.push(aKey);
    });
    // insert all column headers
    let flattenedObj: any = {};
    columns.forEach((aKey: string, index: number) => {
      const excelCoor = coordinateToCellName([1, index + 1]);
      flattenedObj[excelCoor] = aKey;
    });
    // insert alert data
    alerts.forEach((anAlert: any, alertIndex: number) => {
      columns.forEach((aKey: string, index: number) => {
        const aField = anAlert[aKey];
        const excelCoor = coordinateToCellName([alertIndex + 2, index + 1]);
        flattenedObj[excelCoor] = aField !== undefined ? JSON.stringify(aField) : 'N/A';
      });
    });

    return flattenedObj;
  } else {
    return {};
  }
};

const ExportModal = ({ alerts, isModalVisible, onCloseModal }: ExportModalProps) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [directory, setDirectory] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');

  const styles = useStyles2(getStyles);

  useEffect(() => {
    if (isModalVisible) {
      setDirectory('');
      setFileName('');
    }
  }, [isModalVisible]);

  const cleanUp = () => {
    setConfirmLoading(false);
  };

  const saveToExcel = () => {
    if (fileName !== '') {
      const flattenedAlertObjs = reformatAlertObj(alerts);
      const worksheet = XLSX.utils.json_to_sheet([flattenedAlertObjs]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Alerts');
      XLSX.writeFile(workbook, `${fileName}.xlsx`, { compression: true });
      onCloseModal();
      cleanUp();
    } else {
      alert('Please input a file name and select a directory for export!');
    }
  };

  return (
    <CModal
      width={600}
      title={<strong style={{ display: 'flex', justifyContent: 'flex-start' }}>Export alerts</strong>}
      open={isModalVisible}
      onOk={() => {
        saveToExcel();
      }}
      onCancel={() => {
        onCloseModal();
        cleanUp();
      }}
      confirmLoading={confirmLoading}
      maskClosable={false}
      style={{ textAlign: 'start' }}
    >
      <div className={styles.container}>
        <span style={{ color: 'grey' }}>{`Export displaying alerts to a csv file: `}</span>

        <div className={styles.inputContainer}>
          <Input
            size="middle"
            id="wires-excel-export-name"
            value={fileName}
            onChange={(event) => {
              setFileName(event.target.value);
            }}
            placeholder="Please input the file name..."
          />
        </div>

        {directory !== '' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 5,
              width: '100%',
            }}
          >
            <strong style={{ color: 'black' }}>Directory: </strong>

            <Tooltip title={directory}>
              <span
                style={{
                  color: 'black',
                  width: 340,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {directory}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    </CModal>
  );
};

export default ExportModal;
