import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';
import { PanelData } from '@grafana/data';

interface PanelDataContextType {
  panelData: PanelData | undefined;
  setPanelData: Dispatch<SetStateAction<PanelData | undefined>>;
}

interface PanelDataProviderProps {
  children: ReactNode;
}

const PanelDataContext = createContext<PanelDataContextType | undefined>(undefined);

const PanelDataProvider: FC<PanelDataProviderProps> = ({ children }) => {
  const [panelData, setPanelData] = useState<PanelData | undefined>(undefined);

  return <PanelDataContext.Provider value={{ panelData, setPanelData }}>{children}</PanelDataContext.Provider>;
};

const usePanelData = () => {
  const context = useContext(PanelDataContext);
  if (!context) {
    throw new Error('usePanelData must be used within a PanelDataProvider');
  }
  return context;
};

export { PanelDataProvider, usePanelData };
