import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Select } from 'antd';
import { isEmpty, union } from 'lodash';

import { generateSelectOptions } from '../utils';
import { Alerts, AlertsType, Entities, EntitiesType, Severities, SeveritiesType } from '../types';
import { useDataSource } from '../helpers/DataSourceContext';
import CModal from '../commons/c-modal/CModal';

interface FilterModalProps {
  refreshList: VoidFunction;
  onModalClose?: VoidFunction;
  selectedHosts: string[];
  selectedSources: string[];
  selectedEntitiesType: EntitiesType[];
  selectedAlertsType: AlertsType[];
  selectedSeveritiesType: SeveritiesType[];
  onApplyFilters: (item: any) => void;
  presettedHosts: string[];
  presettedSources: string[];
  presettedEntitiesType: EntitiesType[];
  presettedAlertsType: AlertsType[];
  presettedSeveritiesType: SeveritiesType[];
}

export type FilterModalRef = {
  openModal: () => void;
};

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const staticEntityTypeOptions = generateSelectOptions(Entities);
const staticAlertTypeOptions = generateSelectOptions(Alerts);
const staticSeverityOptions = generateSelectOptions(Severities);

const FilterModal = forwardRef<FilterModalRef, FilterModalProps>(
  (
    {
      refreshList,
      onModalClose,
      selectedHosts,
      selectedSources,
      selectedEntitiesType,
      selectedAlertsType,
      selectedSeveritiesType,
      onApplyFilters,
      presettedHosts,
      presettedSources,
      presettedEntitiesType,
      presettedAlertsType,
      presettedSeveritiesType,
    },
    ref
  ) => {
    const [form] = Form.useForm();

    const [isModalVisible, setIsModalOpen] = useState<boolean>(false);
    const [hostOptions, setHostOptions] = useState<Array<{ label: string; value: string }>>([]);
    const [presettedSourceOptions, setPresettedSourceOptions] = useState<
      Array<{
        label: string;
        value: string;
        disabled?: boolean;
      }>
    >([]);
    const [entityTypeOptions, setEntityTypeOptions] =
      useState<Array<{ label: string; value: string }>>(staticEntityTypeOptions);
    const [alertTypeOptions, setAlertTypeOptions] =
      useState<Array<{ label: string; value: string }>>(staticAlertTypeOptions);
    const [severityOptions, setSeverityOptions] =
      useState<Array<{ label: string; value: string }>>(staticSeverityOptions);
    const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(false);

    const { dataSource } = useDataSource();

    useEffect(() => {
      const fetchLocations = async () => {
        try {
          setIsLocationsLoading(true);
          const locations = await dataSource?.services?.locationService?.getLocationsList(true, true, true);

          const _hostOptions = locations.flatMap((location: any) =>
            (location.groups || []).flatMap((group: any) =>
              (group.hosts || [])
                .map((host: any) => ({
                  label: `${location.name} : ${group.name} : ${host.name}`,
                  value: host.uuid,
                  disabled: Array.isArray(presettedHosts) && presettedHosts.includes(host.uuid),
                }))
                .filter((host: any) => presettedHosts.includes(host.value))
            )
          );

          setHostOptions(_hostOptions);
        } finally {
          setIsLocationsLoading(false);
        }
      };

      if (!isEmpty(dataSource)) {
        void fetchLocations();
      }
    }, [dataSource, presettedHosts]);

    useEffect(() => {
      if (Array.isArray(presettedSources)) {
        setPresettedSourceOptions(presettedSources.map((ps: any) => ({ label: ps, value: ps, disabled: true })));
      }
      if (Array.isArray(presettedEntitiesType)) {
        setEntityTypeOptions((prevEntitiesOptions: any) =>
          prevEntitiesOptions.map((so: any) => ({
            ...so,
            disabled: presettedEntitiesType.includes(so.value),
          }))
        );
      }
      if (Array.isArray(presettedAlertsType)) {
        setAlertTypeOptions((prevAlertTypeOptions: any) =>
          prevAlertTypeOptions.map((so: any) => ({
            ...so,
            disabled: presettedAlertsType.includes(so.value),
          }))
        );
      }
      if (Array.isArray(presettedSeveritiesType)) {
        setSeverityOptions((prevSeverityOptions: any) =>
          prevSeverityOptions.map((so: any) => ({
            ...so,
            disabled: presettedSeveritiesType.includes(so.value),
          }))
        );
      }
    }, [presettedSources, presettedEntitiesType, presettedAlertsType, presettedSeveritiesType]);

    const openModal = () => {
      form.setFieldsValue({
        host_uuid: union(presettedHosts, selectedHosts),
        source: union(presettedSources, selectedSources),
        entity_type: union(presettedEntitiesType, selectedEntitiesType),
        type: union(presettedAlertsType, selectedAlertsType),
        severity: union(presettedSeveritiesType, selectedSeveritiesType),
      });
      setIsModalOpen(true);
    };

    const closeModal = () => {
      // Not resetting form with ```form.resetFields()``` as not to visually remove presetted value
      onModalClose?.();
      setIsModalOpen(false);
    };

    const handleSubmit = (item: any) => {
      onApplyFilters(
        Object.fromEntries(
          Object.entries(item).map(([key, arr]) => [
            key,
            Array.isArray(arr)
              ? arr.filter((item) => {
                  if (key === 'host_uuid') {
                    return !presettedHosts.includes(item);
                  }
                  if (key === 'source') {
                    return !presettedSources.includes(item);
                  }
                  if (key === 'entity_type') {
                    return !presettedEntitiesType.includes(item);
                  }
                  if (key === 'type') {
                    return !presettedAlertsType.includes(item);
                  }
                  if (key === 'severity') {
                    return !presettedSeveritiesType.includes(item);
                  }
                  return true; // Keep items for other keys
                })
              : arr,
          ])
        )
      );
      setIsModalOpen(false);
    };

    useImperativeHandle(ref, () => ({
      openModal: () => {
        openModal();
      },
    }));

    return (
      <CModal
        title="Filter Alerts"
        open={isModalVisible}
        onCancel={closeModal}
        onOk={form.submit}
        maskClosable={false}
        closable={false}
        cancelText="Reset & Close Filters"
      >
        <Form {...formLayout} form={form} name="control-hooks" onFinish={handleSubmit} style={{ maxWidth: '42rem' }}>
          <Form.Item name="host_uuid" label="Host">
            <Select
              mode="multiple"
              placeholder="Select one or more host"
              options={hostOptions}
              loading={isLocationsLoading}
            />
          </Form.Item>

          <Form.Item name="source" label="Source">
            <Select
              placeholder="Enter multiple sources with commas"
              mode="tags"
              tokenSeparators={[',']}
              suffixIcon={null}
              dropdownStyle={{ display: 'none' }}
              options={presettedSourceOptions}
            />
          </Form.Item>

          <Form.Item name="entity_type" label="Entity Type">
            <Select mode="multiple" placeholder="Select one or more entity type" options={entityTypeOptions} />
          </Form.Item>

          <Form.Item name="type" label="Alert Type">
            <Select mode="multiple" placeholder="Select one or more alert type" options={alertTypeOptions} />
          </Form.Item>

          <Form.Item name="severity" label="Severity">
            <Select mode="multiple" placeholder="Select one or more severity" options={severityOptions} />
          </Form.Item>
        </Form>
      </CModal>
    );
  }
);

FilterModal.displayName = 'FilterModal';

export default FilterModal;
