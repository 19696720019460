import React, { useCallback, useState } from 'react';

import { ContextMenuData } from '../commons/context-menu/types';

export const useContextMenu = <T>() => {
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [contextMenuData, setContextMenuData] = useState<ContextMenuData<T>>();

  const openMenu = useCallback((item: T, event: React.MouseEvent | MouseEvent) => {
    const panelContainer = document.getElementById('panelContainer');
    let containerLeft = 0;
    let containerTop = 0;

    if (panelContainer) {
      const panelContainerRect = panelContainer.getBoundingClientRect();
      containerLeft = panelContainerRect.left;
      containerTop = panelContainerRect.top;
    }

    setContextMenuData({
      record: item,
      xPos: event.clientX - containerLeft + 8, // As Grafana add padding of 8 around it
      yPos: event.clientY - containerTop + 8,
    });
    setIsContextMenuVisible(true);

    document.addEventListener('click', function onClickOutside() {
      setIsContextMenuVisible(false);
      document.removeEventListener('click', onClickOutside);
    });
  }, []);

  const handleRightClick = useCallback(
    (item: T, event: React.MouseEvent | MouseEvent) => {
      event.preventDefault();
      openMenu(item, event);
    },
    [openMenu]
  );

  return {
    contextMenuData,
    isContextMenuVisible,
    handleRightClick,
  };
};
