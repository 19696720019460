import { create } from 'zustand';

interface TeamsState {
  teams: any[];
  setTeams: (teams: any[]) => void;
  isTeamsLoading: boolean;
  setIsTeamsLoading: (isLoading: boolean) => void;
}

export const useTeamsStore = create<TeamsState>((set) => ({
  teams: [],
  setTeams: (_teams: any[]) => set({ teams: _teams }),
  isTeamsLoading: false,
  setIsTeamsLoading: (isLoading: boolean) => set({ isTeamsLoading: isLoading }),
}));
