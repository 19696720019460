import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { useStyles2, useTheme2 } from '@grafana/ui';
import { css, cx } from '@emotion/css';
import { getDataSourceSrv, PanelDataErrorView } from '@grafana/runtime';
import { ConfigProvider, theme as adTheme } from 'antd';

import { SimpleOptions } from 'types';
import AlertsTable from './AlertsTable';
import { DataSourceProvider } from '../helpers/DataSourceContext';
import { PanelDataProvider } from '../helpers/PanelDataContext';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      position: relative;
    `,
    container: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
    `,
    warningText: css`
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #999;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
    `,
  };
};

const RUBIX_FRAMEWORK_DATASOURCE_ID = 'grafana-rubix-os-data-source';

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {
  const theme = useTheme2();
  const styles = useStyles2(getStyles);

  const [isDataSourceConfigured, setIsDataSourceConfigured] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any>({});

  useEffect(() => {
    const dataSources = data?.request?.targets?.map((x) => x.datasource);

    if (Array.isArray(dataSources) && dataSources.length > 0) {
      dataSources.map(async (dataSource) => {
        try {
          const res = await getDataSourceSrv().get(dataSource);
          if (res.meta.id === RUBIX_FRAMEWORK_DATASOURCE_ID) {
            setDataSource(res);
            setIsDataSourceConfigured(true);
          } else {
            setIsDataSourceConfigured(false);
          }
        } catch (err) {
          console.error(err);
        }
      });
    }
  }, [data]);

  if (!isDataSourceConfigured) {
    return (
      <div className={styles.container}>
        <p className={styles.warningText}>Selected datasource is not correct!</p>
      </div>
    );
  }

  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }

  return (
    <div
      id="panelContainer"
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <ConfigProvider
        theme={{
          algorithm: theme.isDark ? adTheme.darkAlgorithm : adTheme.defaultAlgorithm,
        }}
      >
        <PanelDataProvider>
          <DataSourceProvider>
            <AlertsTable dataSource={dataSource} panelData={data} panelHeight={height} />
          </DataSourceProvider>
        </PanelDataProvider>
      </ConfigProvider>
    </div>
  );
};
