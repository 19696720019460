export const AlertsStatuses = ['active', 'acknowledged', 'closed'] as const;
export const Entities = ['gateway', 'network', 'device', 'point', 'service'] as const;
export const Alerts = ['ping', 'fault', 'threshold', 'flat-line'] as const;
export const Severities = ['crucial', 'minor', 'warning', 'info'] as const;
export const Targets = ['mobile', 'none'] as const;
export const TicketPriorityTypes = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL'] as const;
export const TicketStatusTypes = ['NEW', 'REPLIED', 'RESOLVED', 'CLOSED', 'BLOCKED'] as const;

export type AlertsStatusesType = (typeof AlertsStatuses)[number];
export type EntitiesType = (typeof Entities)[number];
export type AlertsType = (typeof Alerts)[number];
export type SeveritiesType = (typeof Severities)[number];

export interface DataFieldKeyI {
  [key: string]: string;
}

interface Tag {
  tag: string;
  networks: any[];
  devices: any[];
  points: any[];
  alerts: Alert[];
  teams: any[];
  hosts: any[];
  alert_conditions: any[];
}

interface AlertMetaTag {
  alert_uuid: string;
  key: string;
  value: string;
}

interface AlertTeam {
  alert_uuid: string;
  team_uuid: string;
}

interface AlertTransaction {
  uuid: string;
  alert_uuid: string;
  status: string;
  severity: string;
  target: string;
  title: string;
  body: string;
  created_at: string;
}

export interface Alert {
  uuid: string;
  host_uuid: string;
  entity_type: string;
  entity_uuid: string;
  type: string;
  status: string;
  severity: string;
  target: string;
  title: string;
  body: string;
  source: string;
  notified: boolean;
  notified_at: string;
  emailed: boolean;
  emailed_at: string;
  created_at: string;
  last_updated: string;
  tags: Tag[];
  meta_tags: AlertMetaTag[];
  tickets: any[];
  teams: AlertTeam[];
  transactions: AlertTransaction[];
}

export type AlertKeys = keyof Alert;

export interface SimpleOptions {}
