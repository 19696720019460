import React, { useCallback, useEffect, useState } from 'react';
import { Empty, Space, Spin, Typography } from 'antd';
import { BarsOutlined } from '@ant-design/icons';

import AlertTransactionsTable from './AlertTransactionsTable';
import { useDataSource } from '../helpers/DataSourceContext';

interface AlertTransactionsProps {
  alertUUID: string;
  isExpanded: boolean;
}

const AlertTransactions = ({ alertUUID, isExpanded }: AlertTransactionsProps) => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const { dataSource } = useDataSource();

  const fetchAlertTransactions = useCallback(async () => {
    try {
      setIsFetching(true);
      const alert = await dataSource?.services?.alertService?.fetchAlert(alertUUID);
      setTransactions((alert.transactions ?? []).map((item: any) => item));
    } finally {
      setIsFetching(false);
    }
  }, [alertUUID]);

  useEffect(() => {
    if (isExpanded) {
      void fetchAlertTransactions();
    }
  }, [isExpanded, fetchAlertTransactions]);

  if (isFetching) {
    return (
      <Space direction="vertical" align="center" style={{ width: '100%', margin: '3rem' }}>
        <Spin />
      </Space>
    );
  }

  if (transactions.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No transactions'} />;
  }

  return (
    <>
      <Typography.Title level={4}>
        <BarsOutlined style={{ marginRight: '0.5rem' }} />
        {'Transactions'}
      </Typography.Title>

      <AlertTransactionsTable transactions={transactions} />
    </>
  );
};

export default AlertTransactions;
