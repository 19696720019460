type TableDataType<T> = T & {
  uuid: string;
};

type HighlightEditedRowParams<TableDataType> = {
  setFilteredData: (data: TableDataType[]) => void;
};

export const useHighlightEditedRow = <T>(params: HighlightEditedRowParams<T>) => {
  const { setFilteredData } = params;

  const handleFetchSuccess = (successData: Array<TableDataType<T>>, editedRowId?: string | string[]) => {
    if (!editedRowId) {
      setFilteredData(successData);
      return;
    }

    const dataWithEditedValue = successData.map((data) => {
      const isEditedRow = (() => {
        if (Array.isArray(editedRowId)) {
          return (editedRowId as string[]).some((rowId) => rowId === data.uuid);
        }

        return data.uuid === editedRowId;
      })();

      if (isEditedRow) {
        return {
          ...data,
          isEdited: true,
        };
      }

      return data;
    });

    setFilteredData(dataWithEditedValue);
    setTimeout(() => setFilteredData(successData), 2000);
  };

  return {
    handleFetchSuccess,
  };
};
