import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Form, Select } from 'antd';
import dayjs from 'dayjs';

import CModal from '../commons/c-modal/CModal';
import { generateSelectOptions } from '../utils';
import { AlertsStatuses } from '../types';
import { useDataSource } from '../helpers/DataSourceContext';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';

interface UpdateStatusModalProps {
  isModalVisible: boolean;
  onCloseModal: VoidFunction;
  refreshList: (params: { editedRowId: string }) => void;
  alert: any;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const alertsStatusOptions = generateSelectOptions(AlertsStatuses);

const getStyles = () => {
  return {
    formContainer: css({
      maxWidth: '42rem',
      marginTop: '2.5rem',
    }),
    datePicker: css({
      width: '100%',
    }),
  };
};

const UpdateStatusModal = ({ isModalVisible, onCloseModal, alert, refreshList }: UpdateStatusModalProps) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [alertStatus, setAlertStatus] = useState<string | undefined>(undefined);
  const [form] = Form.useForm<any>();
  const styles = useStyles2(getStyles);

  const { dataSource } = useDataSource();

  useEffect(() => {
    if (isModalVisible) {
      setAlertStatus(alert.status);
      form.setFieldsValue({
        status: alert.status,
        acknowledge_timeout: alert.acknowledge_timeout ? dayjs(alert.acknowledge_timeout) : undefined,
      });
    }
  }, [isModalVisible]);

  const handleClose = useCallback(() => {
    form.resetFields();
    onCloseModal();
    setAlertStatus(undefined);
  }, []);

  const handleSubmit = async (item: any) => {
    try {
      const status = item.status;
      const acknowledge_timeout = item?.acknowledge_timeout ? item.acknowledge_timeout?.toISOString() : '';
      setConfirmLoading(true);
      if (status === 'acknowledged') {
        await dataSource?.services?.alertService?.updateAlertStatusAndAckTimeout(
          alert.uuid,
          status,
          acknowledge_timeout
        );
      } else {
        await dataSource?.services?.alertService?.updateAlertStatus(alert.uuid, item.status);
      }
      refreshList({ editedRowId: alert.uuid });
      handleClose();
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <CModal
      title={`Update alert status for: ${alert.title ?? alert.uuid}`}
      open={isModalVisible}
      onOk={form.submit}
      onCancel={handleClose}
      confirmLoading={confirmLoading}
      maskClosable={false}
      style={{ textAlign: 'start' }}
    >
      <Form
        {...formLayout}
        form={form}
        name="control-hooks"
        onFinish={handleSubmit}
        className={styles.formContainer}
        layout="vertical"
      >
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select
            options={alertsStatusOptions}
            onChange={(value) => {
              setAlertStatus(value);
            }}
          />
        </Form.Item>

        {alertStatus === 'acknowledged' && (
          <Form.Item name="acknowledge_timeout" label="Acknowledge timeout">
            <DatePicker showTime format="YYYY-MM-DD HH:mm" className={styles.datePicker} />
          </Form.Item>
        )}
      </Form>
    </CModal>
  );
};

export default UpdateStatusModal;
