import React, { CSSProperties, FC, useEffect } from 'react';
import {
  CheckCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
  ExportOutlined,
  TagOutlined,
  TagsOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import ContextMenu from '../commons/context-menu/ContextMenu';
import { useAlertsContextMenuStore } from '../hooks/useAlertsContextMenuStore';
import { ContextMenuData } from '../commons/context-menu/types';

interface AlertsTableContextMenuProps {
  isVisible: boolean;
  contextMenuData?: ContextMenuData<any>;
  selectedUUIDs: string[];
  bulkDelete: () => void;
}

export const AlertsTableContextMenu: FC<AlertsTableContextMenuProps> = ({
  isVisible,
  contextMenuData,
  selectedUUIDs,
  bulkDelete,
}) => {
  const {
    setSelectedAlert,
    setIsAssignTeamsModalVisible,
    setIsTagModalVisible,
    setIsUpdateStatusModelVisible,
    setIsBulkUpdateStatusModelVisible,
    setIsBulkAssignTeamsModalVisible,
    setIsExportModalVisible,
    setIsBulkTagModalVisible,
  } = useAlertsContextMenuStore();

  const record = contextMenuData?.record;
  const singleStyle: CSSProperties = selectedUUIDs.length === 0 ? {} : { pointerEvents: 'none', opacity: 0.6 };
  const multiStyle: CSSProperties = selectedUUIDs.length === 0 ? { pointerEvents: 'none', opacity: 0.6 } : {};

  useEffect(() => {
    record && setSelectedAlert(record);
  }, [isVisible, record]);

  const tableContextMenuItems = [
    {
      Icon: <CheckOutlined />,
      label: 'Update Status',
      onClick: () => setIsUpdateStatusModelVisible(true),
      style: singleStyle,
    },
    {
      Icon: <UserOutlined />,
      label: 'Assign Teams',
      onClick: () => setIsAssignTeamsModalVisible(true),
      style: singleStyle,
    },
    {
      Icon: <TagOutlined />,
      label: 'Edit Tag',
      onClick: () => setIsTagModalVisible(true),
      style: singleStyle,
    },
    {
      Icon: <DeleteOutlined />,
      label: 'Bulk Delete',
      onClick: () => bulkDelete(),
      style: multiStyle,
    },
    {
      Icon: <CheckCircleOutlined />,
      label: 'Bulk Update Status',
      onClick: () => setIsBulkUpdateStatusModelVisible(true),
      style: multiStyle,
    },
    {
      Icon: <TeamOutlined />,
      label: 'Bulk Update Teams',
      onClick: () => setIsBulkAssignTeamsModalVisible(true),
      style: multiStyle,
    },
    {
      Icon: <ExportOutlined />,
      label: 'Bulk Export',
      onClick: () => setIsExportModalVisible(true),
      style: multiStyle,
    },
    {
      Icon: <TagsOutlined />,
      label: 'Bulk Tags',
      onClick: () => setIsBulkTagModalVisible(true),
      style: multiStyle,
    },
  ];

  return (
    <ContextMenu contextMenuData={contextMenuData} isVisible={isVisible} contextMenuItems={tableContextMenuItems} />
  );
};
