import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { RedoOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select, Tooltip } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import CModal from '../commons/c-modal/CModal';

dayjs.extend(utc);
const { RangePicker } = DatePicker;

export enum IntervalByType {
  createdAt = 'created_at',
  updatedAt = 'last_updated',
  notifiedAt = 'notified_at',
}

interface AdvanceSearchModalProps {
  onModalClose?: VoidFunction;
  timeInterval: string[] | null;
  setTimeInterval: (val: string[] | null) => void;
  intervalBy: any;
  setIntervalBy: (val: any) => void;
}

export type AdvanceSearchModalRef = {
  openModal: () => void;
};

type RangeValue = [any | null, any | null] | null;

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      rowGap: '5px',
    }),
    innerContainer: css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: '5px',
    }),
  };
};

const intervalByOptions = [
  { value: IntervalByType.createdAt, label: IntervalByType.createdAt },
  { value: IntervalByType.updatedAt, label: IntervalByType.updatedAt },
  { value: IntervalByType.notifiedAt, label: IntervalByType.notifiedAt },
];

const AdvanceSearchModal = forwardRef<AdvanceSearchModalRef, AdvanceSearchModalProps>(
  ({ onModalClose, timeInterval, setTimeInterval, intervalBy, setIntervalBy }, ref) => {
    const [isModalVisible, setIsModalOpen] = useState<boolean>(false);
    const [rangePickerValue, setRangePickerValue] = useState<RangeValue | undefined>(undefined);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const cleanUp = () => {
      setConfirmLoading(false);
      setRangePickerValue(undefined);
    };

    const openModal = () => {
      if (timeInterval !== null) {
        setRangePickerValue([dayjs(timeInterval[0]).local(), dayjs(timeInterval[1]).local()]);
      }
      setIsModalOpen(true);
    };

    const closeModal = () => {
      onModalClose?.();
      cleanUp();
      setIsModalOpen(false);
    };

    useImperativeHandle(ref, () => ({
      openModal: () => {
        openModal();
      },
    }));

    const handleChange = (value: string[]) => {
      setIntervalBy(value);
    };

    const onRangePickerChange = (dates: any, dateStrings: [string, string]) => {
      if (dates !== null) {
        setRangePickerValue([dates[0], dates[1]]);
        setTimeInterval([dates[0].toISOString(), dates[1].toISOString()]);
      } else {
        setRangePickerValue(undefined);
        setTimeInterval(null);
      }
    };

    const handleModalOk = () => {
      setIsModalOpen(false);
      cleanUp();
    };

    const styles = useStyles2(getStyles);

    return (
      <CModal
        width={700}
        title="Advance Search"
        open={isModalVisible}
        confirmLoading={confirmLoading}
        onCancel={closeModal}
        onOk={handleModalOk}
        maskClosable={false}
      >
        <div className={styles.container}>
          <span style={{ color: 'grey' }}>{`Filter alerts based on time interval: `}</span>

          <div className={styles.innerContainer}>
            <Tooltip title={'reset'}>
              <Button
                icon={<RedoOutlined />}
                onClick={() => {
                  setTimeInterval(null);
                  setIntervalBy(undefined);
                  setRangePickerValue(undefined);
                }}
              />
            </Tooltip>

            <Select
              style={{ width: '210px' }}
              allowClear
              value={intervalBy}
              placeholder="Please select search type"
              onChange={handleChange}
              options={intervalByOptions}
            />

            <RangePicker
              style={{ width: '400px' }}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={rangePickerValue}
              onChange={onRangePickerChange}
            />
          </div>
        </div>
      </CModal>
    );
  }
);

AdvanceSearchModal.displayName = 'AdvanceSearchModal';

export default AdvanceSearchModal;
