export const useCalculateColumnsWidth = (columns: any, source: any, maxWidthPerCell = 500) => {
  const getTextWidth = (text: any, font = '14px -apple-system') => {
    const canvas = document.createElement('canvas');
    const context: any = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return Math.round(metrics.width + 80);
  };

  // First we calculate the width for each column
  // The column width is based on its string length
  const columnsWithWidth = columns.map((column: any) => {
    if (!column?.render) {
      const _column = { ...column };
      let isMassEditTitle = false;
      let titleName = '';
      if (typeof column?.title === 'string') {
        titleName = column.title;
      } else {
        if (column?.key) {
          titleName = column.key;
          isMassEditTitle = true;
        }
      }
      _column.width = getTextWidth(titleName);
      // allow more space to accommodate the mass edit button
      _column.width += isMassEditTitle ? 40 : 0;
      return _column;
    } else {
      return { ...column };
    }
  });

  // Since we have a minimum width (column's width already calculated),
  // now we are going to verify if the cell value is bigger
  // than the column width which is already set
  source.forEach((entry: any) => {
    columnsWithWidth.forEach((column: any, indexColumn: any) => {
      if (!column.render) {
        const columnWidth = column.width;
        const cellValue = entry[column.dataIndex] || '';

        // Get the string width based on chars length
        let cellWidth = getTextWidth(cellValue);

        // Verify if the cell value is smaller than column's width
        if (cellWidth < columnWidth) {
          cellWidth = columnWidth;
        }

        // Verify if the cell value width is bigger than our max width flag
        if (cellWidth > maxWidthPerCell) {
          cellWidth = maxWidthPerCell;
        }

        // Update the column width
        columnsWithWidth[indexColumn].width = cellWidth;
      }
    });
  });

  // Sum of all columns width to determine the table max width
  const tableWidth = columnsWithWidth
    .map((column: any) => column.width)
    .reduce((a: any, b: any) => {
      return a + b;
    }, 0);

  return {
    columns: columnsWithWidth,
    source,
    tableWidth,
  };
};
