import { create } from 'zustand/react';

type State = {
  selectedAlert: any;
  isAssignTeamsModalVisible: boolean;
  isUpdateStatusModalVisible: boolean;
  isBulkAssignTeamsModalVisible: boolean;
  isBulkUpdateStatusModalVisible: boolean;
  isExportModalVisible: boolean;
  isTagModalVisible: boolean;
  isBulkTagModalVisible: boolean;
};

type Actions = {
  setSelectedAlert: (ticket: any) => void;
  setIsAssignTeamsModalVisible: (isLoading: boolean) => void;
  setIsUpdateStatusModelVisible: (isLoading: boolean) => void;
  setIsBulkAssignTeamsModalVisible: (isLoading: boolean) => void;
  setIsBulkUpdateStatusModelVisible: (isLoading: boolean) => void;
  setIsExportModalVisible: (isLoading: boolean) => void;
  setIsTagModalVisible: (isLoading: boolean) => void;
  setIsBulkTagModalVisible: (isLoading: boolean) => void;
  resetContentMenuModalState: () => void;
};

const initialState: State = {
  selectedAlert: null,
  isAssignTeamsModalVisible: false,
  isUpdateStatusModalVisible: false,
  isBulkAssignTeamsModalVisible: false,
  isBulkUpdateStatusModalVisible: false,
  isExportModalVisible: false,
  isTagModalVisible: false,
  isBulkTagModalVisible: false,
};

export const useAlertsContextMenuStore = create<State & Actions>((set) => ({
  ...initialState,
  setSelectedAlert: (ticket: any) => {
    set({ selectedAlert: ticket });
  },
  setIsAssignTeamsModalVisible: (isVisible: boolean) => set({ isAssignTeamsModalVisible: isVisible }),
  setIsUpdateStatusModelVisible: (isVisible: boolean) => set({ isUpdateStatusModalVisible: isVisible }),
  setIsBulkAssignTeamsModalVisible: (isVisible: boolean) => set({ isBulkAssignTeamsModalVisible: isVisible }),
  setIsBulkUpdateStatusModelVisible: (isVisible: boolean) => set({ isBulkUpdateStatusModalVisible: isVisible }),
  setIsExportModalVisible: (isVisible: boolean) => set({ isExportModalVisible: isVisible }),
  setIsTagModalVisible: (isVisible: boolean) => set({ isTagModalVisible: isVisible }),
  setIsBulkTagModalVisible: (isVisible: boolean) => set({ isBulkTagModalVisible: isVisible }),
  resetContentMenuModalState: () => set(initialState),
}));
