import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';

import CModal from '../commons/c-modal/CModal';
import { useDataSource } from '../helpers/DataSourceContext';

interface FCMServerModalProps {
  onOkPress?: VoidFunction;
  onModalClose?: VoidFunction;
}

export type FCMServerModalRef = {
  openModal: () => void;
};

const getStyles = () => {
  return {
    formContainer: css({
      maxWidth: '48rem',
    }),
  };
};

const FCMServerModal = forwardRef<FCMServerModalRef, FCMServerModalProps>(({ onOkPress, onModalClose }, ref) => {
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalOpen] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const { dataSource } = useDataSource();

  const getFCMServerKey = async () => {
    try {
      setIsDataLoading(true);
      const fcmServer = await dataSource?.services?.fcmService?.fetchAll();
      form.setFieldsValue({
        key: fcmServer.key,
      });
    } finally {
      setIsDataLoading(false);
    }
  };

  const openModal = () => {
    void getFCMServerKey();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    onModalClose?.();
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleSubmit = async (item: any) => {
    try {
      setConfirmLoading(true);
      await dataSource?.services?.fcmService?.put('', item);
      closeModal();
    } finally {
      setConfirmLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    openModal: () => {
      openModal();
    },
  }));

  const styles = useStyles2(getStyles);

  return (
    <CModal
      title="FCM Server"
      open={isModalVisible}
      confirmLoading={confirmLoading}
      onCancel={closeModal}
      onOk={form.submit}
      maskClosable={false}
    >
      <Spin spinning={isDataLoading}>
        <Form form={form} name="control-hooks" onFinish={handleSubmit} className={styles.formContainer}>
          <Form.Item
            name="key"
            label="Key"
            rules={[
              { required: true, message: 'FCM server key is required' },
              {
                min: 20,
                message: 'Key should be at least 20 characters long',
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Input placeholder="FCM server key" allowClear />
          </Form.Item>
        </Form>
      </Spin>
    </CModal>
  );
});

FCMServerModal.displayName = 'FCMServerModal';

export default FCMServerModal;
