import React, { Dispatch, forwardRef, SetStateAction, useImperativeHandle, useState } from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { Button, Select, Tooltip } from 'antd';
import { CheckSquareOutlined } from '@ant-design/icons';

import CModal from '../commons/c-modal/CModal';
import { getAlertSchema } from '../utils';

interface ColumnOrganizeModalProps {
  onOkPress?: VoidFunction;
  onModalClose?: VoidFunction;
  setReorgCols: Dispatch<SetStateAction<string[]>>;
  setFixedCols: Dispatch<SetStateAction<string[]>>;
  tableColumns: any;
}

export type ColumnOrganizeModalRef = {
  openModal: () => void;
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      rowGap: '5px',
    }),
    firstInner: css({
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: '5px',
      columnGap: '5px',
    }),
  };
};

export const basicPreset = [
  'actions',
  'title',
  'status',
  'severity',
  'type',
  'body',
  'created_at',
  'acknowledge_timeout',
  'notified_at',
  'notified',
  'uuid',
  'entity_uuid',
  'source',
  'tags',
  'meta_tags',
];

export const basicFixed = ['actions', 'title'];

const presetOptions = [
  {
    label: 'Basics',
    value: JSON.stringify(basicPreset),
  },
];

const ColumnOrganizeModal = forwardRef<ColumnOrganizeModalRef, ColumnOrganizeModalProps>(
  ({ onOkPress, onModalClose, setReorgCols, setFixedCols, tableColumns }, ref) => {
    const [isModalVisible, setIsModalOpen] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [selectedPresetVal, setSelectedPresetVal] = useState<string | undefined>(undefined);
    const [selectedCols, setSelectedCols] = useState<string[]>([]);
    const [selectedFixedCols, setSelectedFixedCols] = useState<string[]>([]);
    const [colNameOptions, setColNameOptions] = useState<any[]>([]);
    const [allColNames, setAllColNames] = useState<string[]>([]);

    useImperativeHandle(ref, () => ({
      openModal: () => {
        void openModal();
      },
    }));

    const fetchColumns = () => {
      const alertKeys = getAlertSchema();
      const options = alertKeys.map((aColName: string) => {
        return {
          value: aColName,
          label: aColName,
        };
      });

      const updatedOptions = [{ value: 'actions', label: 'actions' }, ...options];
      setColNameOptions(updatedOptions);
      setAllColNames(['actions', ...alertKeys]);
    };

    const openModal = async () => {
      fetchColumns();

      // Go through existing cols to set initial values
      let _cols: string[] = [];
      let _fixedCols: string[] = [];
      tableColumns.forEach((aCol: any) => {
        if (aCol) {
          _cols.push(aCol?.dataIndex);
          aCol.fixed && _fixedCols.push(aCol.dataIndex);
        }
      });
      setSelectedCols(_cols);
      setSelectedFixedCols(_fixedCols);

      setIsModalOpen(true);
    };

    const handlePresetChange = (value: string | undefined) => {
      if (value === undefined) {
        setSelectedCols([]);
      } else {
        setSelectedCols(JSON.parse(value));
      }
      setSelectedPresetVal(value);
      setSelectedFixedCols([]);
    };

    const handleChange = (value: string[]) => {
      setSelectedCols(value);
    };

    const handleFixedColChange = (value: string[]) => {
      setSelectedFixedCols(value);
    };

    const handleSelectAllColumns = () => {
      setSelectedCols(allColNames);
      setSelectedPresetVal(undefined);
      setSelectedFixedCols([]);
    };

    const cleanUp = () => {
      setConfirmLoading(false);
      setColNameOptions([]);
      setSelectedCols([]);
      setSelectedFixedCols([]);
    };

    const closeModal = () => {
      cleanUp();
      onModalClose?.();
      setIsModalOpen(false);
    };

    const handleModalOk = () => {
      setReorgCols(selectedCols);
      setFixedCols(selectedFixedCols);
      cleanUp();
      setIsModalOpen(false);
    };

    const styles = useStyles2(getStyles);

    return (
      <CModal
        title="Rearrange Columns"
        open={isModalVisible}
        confirmLoading={confirmLoading}
        onCancel={closeModal}
        onOk={handleModalOk}
        maskClosable={false}
      >
        <div className={styles.container}>
          <div className={styles.firstInner}>
            <span style={{ color: 'grey' }}>{`Select columns to show: `}</span>

            <Tooltip title="select all columns">
              <Button size="small" icon={<CheckSquareOutlined />} onClick={handleSelectAllColumns} />
            </Tooltip>

            <span>Presets:</span>

            <Select
              allowClear
              value={selectedPresetVal}
              style={{ flex: 1 }}
              placeholder="select preset columns"
              options={presetOptions}
              onChange={handlePresetChange}
              size="small"
            />
          </div>

          <Select
            mode="multiple"
            allowClear
            value={selectedCols}
            style={{ width: '100%' }}
            placeholder="Please select"
            onChange={handleChange}
            options={colNameOptions}
          />

          <span style={{ color: 'grey' }}>{`Select fixed columns: `}</span>

          <Select
            mode="multiple"
            allowClear
            value={selectedFixedCols}
            style={{ width: '100%' }}
            placeholder="Please select columns to fix"
            onChange={handleFixedColChange}
            options={selectedCols.map((aSelectedCol: string) => ({
              value: aSelectedCol,
              label: aSelectedCol,
            }))}
          />
        </div>
      </CModal>
    );
  }
);

ColumnOrganizeModal.displayName = 'ColumnOrganizeModal';

export default ColumnOrganizeModal;
